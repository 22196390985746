import React from 'react'
import {Link} from 'react-router-dom'

export default({paginas,cambiarPagina,current}) =>{
    if(!paginas || !current ){
        return (<></>);
    }
    
    
    if(paginas[0])
    if(paginas[0].label ==="&laquo; Anterior"){
         paginas.shift();
        
    }
    var ultimo = paginas.length
    // console.log(paginas[ultimo-1].label)
    // return (<></>);
    if(paginas[ultimo-1])
    if(paginas[ultimo-1].label ==="Siguiente &raquo;"){
        paginas.pop();
        
    }

    return (
        
        <nav>
        <ul className="pagination pull-left">
            <li className="page-item">
                <span className="page-link">{`Mostrando ${current.current_page} de ${current.last_page}`}</span>
            </li>
           
        </ul>
        <ul className="pagination pull-right">
            <li className={current.current_page===1 ? "page-item disabled" : "page-item"  }>
                <Link className="page-link" to="#" onClick={() => cambiarPagina(current.current_page-1)} >Atras</Link>
            </li>
            {    
            paginas.map((object, index) => (
                <li className={ object.active ? "page-item disabled" :"page-item" } key={index} >
                    <Link className="page-link " to="#"
                     onClick={() => cambiarPagina(object.label) }
                      >
                        { object.label }
                    </Link>
                </li>
            ))
            }
            
            <li className={current.current_page===current.last_page   ? "page-item disabled" : "page-item"  }>
                <Link className="page-link" to="#"  onClick={() => cambiarPagina(current.current_page+1)}>Siguiente</Link>
            </li>
        </ul>

        </nav>
    )
}
