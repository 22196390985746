import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm, Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'



const AgregarAjuste =() => {
    const [cargandop,setCargandop] = useState(true)
    const [productos,setProductos] = useState({})
    const [cargandotp,setCargandotp] = useState(true)
    const [tiposmovimiento,setTipomovimiento] = useState({})
    const [almacenes,setAlmacenes] = useState({})
    const { register, handleSubmit, errors, setValue, control  } = useForm()

    const traerProductos =async () => {
        const productos= await obtenerCatalogo('auth/inventarios/productos-catalogo')
        setProductos(productos);
        setCargandop(false)
        setValue("catproducto_id",{value: null, label: '--Selecciona un valor--'})
    }
    const traerTiposMovimeinto =async () => {
        const tiposmovimiento= await obtenerCatalogo('auth/movimientos/movimientos-catalogo')
        setTipomovimiento(tiposmovimiento);
        setCargandotp(false)
        setValue("cattipomovimiento_id",{value: null, label: '--Selecciona un valor--'})
    }
    const traerAlmacenes =async () => {
        const almacenes= await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogo')
        if(almacenes || almacenes.length===0){
            almacenes.unshift({value: "", label: '--Selecciona un valor--'})
            setAlmacenes(almacenes);
        }else{
            setAlmacenes({value: "", label: '--Selecciona un valor--'})
        }
        
    }

    const onSubmit =async (data,e) => { 
    try {
        document.getElementById("enviar").disabled = true;
        if(data.catproducto_id.value && data.cattipomovimiento_id.value){
            data.catproducto_id = data.catproducto_id.value;
            data.cattipomovimiento_id = data.cattipomovimiento_id.value;
            const response =   await API.post('auth/inventarios/trnmovimientos-agregar', data);
            if(response.status===201){
                swal({
                    title: "Registrado",
                    text: "El ajuste se llevo de manera correctamente",
                    icon: "success",
                    button: "Aceptar"
                }); 
                document.getElementById("enviar").disabled = false;
                history.push('/ajustes')
            
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        }else{
            document.getElementById("enviar").disabled = false;
            document.getElementById("respuesta").innerHTML ="Favor de seleccionar un producto y un tipo de movimiento";
        }
        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
        }
  
    }
    const handleChangeProd = e => {
        setValue("catproducto_id", e);
    }
    const handleChangeTipoMov = e => {
        setValue("cattipomovimiento_id", e);
    }
    useEffect(
        ()=>{
            traerProductos();
            traerTiposMovimeinto();
            traerAlmacenes();
        }
    ,[]);
    return (
    <>
    <Titulo titulo="Agregar ajuste inventario" tituloBajo="Ajustes inventario &gt; Agregar ajuste inventario"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Ajustes inventario</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="catproducto_id">Producto*</label>
                            {cargandop ?
                            "Cargando"
                            :
                            <Controller
                                name="catproducto_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeProd}
                                    options={productos}
                                    isDisabled={false}

                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="cattipomovimiento_id">Tipo movimiento*</label>
                            {cargandotp ?
                            "Cargando"
                            :
                            <Controller
                                name="cattipomovimiento_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeTipoMov}
                                    options={tiposmovimiento}
                                    isDisabled={false}

                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catalmacen_id">Almacen*</label>
                            <select className="form-control" name="catalmacen_id" id="catalmacen_id"   ref={register({ required: true })} >
                                {
                                    almacenes.length >0 ?
                                    almacenes.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catalmacen_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Cantidad">Cantidad*</label>
                            <input type="number" className="form-control" id="Cantidad" name="Cantidad" ref={register({required: true, pattern: /^\d*(\.\d{1})?\d{0,1}$/i})}  />
                            {errors.Cantidad &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debé ser un campo numérico!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Descripción*</label>
                            <textarea  className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div  className="text-right">
                            <Link to="/ajustes" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarAjuste;