import React, { useState } from 'react';
// import React from 'react';
import { Link } from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png';
import iconoCaja from '../../img/icono-caja-registradora.png';
import iconoEntradaSalida from '../../img/icono-entrada-salida.png';
import iconoCajaCerrada from '../../img/icono-caja-registradora-cerrada.png';

const CierresCaja = () => {
    const URLCIERRESDECAJA = 'auth/cierreDeCaja/listar'
    const { cargando, data: cierresDecaja, paginas, cambiarPagina, buscarData, current } = obtenerDatos(URLCIERRESDECAJA);
    const permisos = obtenerPermisos("modulo-cierre-caja");

    const verDatos = () => {
        console.log(cierresDecaja);
    }
    return (
        <>
            <Titulo titulo="Cierres y aperturas de caja" tituloBajo="" ></Titulo>

            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">


                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            <Busqueda
                                buscarData={buscarData}
                                icono={iconoEntradaSalida}
                                permiso={permisos}
                                ruta="/AgregarEntradaSalida"
                                title="Entradas y salidas de efectivo"
                                fechas={true}
                                btns={[
                                    { icono: iconoCaja, permiso: true, ruta: "/abrirCaja", title: "Abrir caja" },
                                    { icono: iconoCajaCerrada, permiso: true, ruta: "/cerrarCaja", title: "Cerrar caja" }
                                ]}
                            >
                            </Busqueda>
                        </div>
                        <div className="pb-5">
                            <div className="table-responsive">
                                {cargando ?
                                    <div className="text-center pt-5">
                                        <h4>Cargando...</h4>
                                    </div>
                                    :
                                    <>
                                        {
                                            cierresDecaja.length > 0 ?
                                                <>
                                                    <table className="table mb-0 table-striped">
                                                        <thead>
                                                            <tr>
                                                                {/* <th scope="col">Folio</th> */}
                                                                <th scope="col">Sucursal</th>
                                                                <th scope="col">Monto apertura</th>
                                                                <th scope="col">Monto cierre</th>
                                                                <th scope="col">Monto ingreso</th>
                                                                <th scope="col">Monto egreso</th>
                                                                <th scope="col">Fecha de apertura</th>
                                                                <th scope="col">Fecha de cierre</th>
                                                                <th scope="col">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cierresDecaja.map((object, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {object.NombreSucursal}
                                                                        </td>
                                                                        <td>
                                                                            {'$' + object.Apertura}
                                                                        </td>
                                                                        <td>
                                                                            {'$' + object.Cierre}
                                                                        </td>
                                                                        <td>
                                                                            {'$' + object.Ingresos}
                                                                        </td>
                                                                        <td>
                                                                            {'$' + object.Egresos}
                                                                        </td>
                                                                        <td>
                                                                            {object.FechaAperturaCC}
                                                                        </td>
                                                                        <td>
                                                                            {object.FechaCierreCC}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                permisos.Visualizar === 1 ?
                                                                                    <Link className="" name="id" to={`/verCierreDeCaja/${object.IdCierresDeCaja}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                                                </>
                                                :
                                                <div className="text-center pt-5">
                                                    <h4>Sin resultados...</h4>
                                                </div>
                                        }

                                    </>
                                }
                            </div>
                        </div>
                        <div class="ml-4" id="respuesta" ></div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default CierresCaja;