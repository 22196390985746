import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png';
import swal from 'sweetalert'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';
// import history from '../../../librerias/history';
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'



const Inventario = () => {
    const URLCATEGORIASPROD = 'auth/inventario/inventario-usuario'
    const URLCATEGORIASPRODELIMINAR = 'auth/inventarios/categorias-eliminar/'
    const { cargando, data: categoriaProd, paginas, cambiarPagina, buscarData, current } = obtenerDatos(URLCATEGORIASPROD);
    const permisos = obtenerPermisos("modulo-inventario-s");
    const [tiposmovimiento, setTipomovimiento] = useState({})
    const [ajuste, setAjuste] = useState(null);
    const [cargandotp, setCargandotp] = useState(true)
    const [almacenes, setAlmacenes] = useState({})

    const { register, handleSubmit, errors, setValue, control } = useForm()


    // console.log(permisos)
    const traerTiposMovimeinto = async () => {
        const tiposmovimiento = await obtenerCatalogo('auth/movimientos/movimientos-catalogo')
        setTipomovimiento(tiposmovimiento);
        setCargandotp(false)
        setValue("cattipomovimiento_id", { value: null, label: '--Selecciona un valor--' })
    }
    const traerAlmacenes = async () => {
        const almacenes = await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogo')
        if (almacenes || almacenes.length === 0) {
            almacenes.unshift({ value: "", label: '--Selecciona un valor--' })
            setAlmacenes(almacenes);
        } else {
            setAlmacenes({ value: "", label: '--Selecciona un valor--' })
        }

    }

    const onSubmit = async (data, e) => {
        try {
            document.getElementById("enviar").disabled = true;
            if (data.cattipomovimiento_id.value) {
                data.catproducto_id = ajuste.id;
                data.cattipomovimiento_id = data.cattipomovimiento_id.value;
                data.catalmacen_id = data.idAlmacen
                const response = await API.post('auth/inventarios/trnmovimientos-agregar', data);
                if (response.status === 201) {
                    swal({
                        title: "Registrado",
                        text: "El ajuste se llevo de manera correctamente",
                        icon: "success",
                        button: "Aceptar"
                    });
                    document.getElementById("enviar").disabled = false;
                    document.getElementById("cerrar-mocal-cliente").click();
                    cambiarPagina(current.current_page)
                } else {
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response, document.getElementById("respuesta"));
                }
            } else {
                document.getElementById("enviar").disabled = false;
                document.getElementById("respuesta-ajuste").innerHTML = "Favor de seleccionar un tipo de movimiento";
            }
        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta-ajuste"));
            if (document.getElementById("enviar"))
                document.getElementById("enviar").disabled = false;
        }

    }
    useEffect(
        () => {
            traerTiposMovimeinto();
            traerAlmacenes();
        }
        , []);

    const handleChangeTipoMov = e => {
        setValue("cattipomovimiento_id", e);
    }
    return (
        <>
            <Titulo titulo="Inventario" tituloBajo="" excel={true} rutaExcel="auth/inventario/generarExcel"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            {/* <h4>Inventario</h4> */}
                            <i className="demo-icon icono-listar" dangerouslySetInnerHTML={{__html: '&#xe80a;'}} ></i>
                            {/* le pongo los persisos en cero para que no salga agregar */}
                            <Busqueda buscarData={buscarData} icono={icono} permiso={{
                                "Crear": 0,
                                "Actualizar": 0,
                                "Visualizar": 0,
                                "Eliminar": 0
                            }} ruta="/AgregarCategoriaProducto" ></Busqueda>
                        </div>
                        <div className="pb-5">
                            <div className="table-responsive">
                                {cargando ?
                                    <div className="text-center pt-5">
                                        <h4>Cargando...</h4>
                                    </div>
                                    :
                                    <>
                                        {
                                            categoriaProd.length > 0 ?
                                                <>
                                                    <table className="table mb-0 table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Almacen</th>
                                                                <th scope="col">Categoría</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">Inventario</th>
                                                                {/* <th scope="col">Fecha Creación</th> */}
                                                                <th scope="col">Código</th>
                                                                <th scope="col">Actualizar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                categoriaProd.map((object, index) => (
                                                                    <tr key={index}>
                                                                        <td>{object.Almacen}</td>
                                                                        <td>{object.Categoria}</td>
                                                                        <td>{object.Nombre}</td>
                                                                        <td>{object.total}</td>
                                                                        <td>
                                                                            {
                                                                                object.Codigo
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                permisos.Actualizar === 1 ?
                                                                                    <a onClick={() => { setAjuste(object) }} data-toggle="modal" data-target="#modal-inventario" type="button" ><i className="demo-icon icon-editar color-tecno">&#xe804;</i></a>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                                                </>
                                                :
                                                <div className="text-center pt-5">
                                                    <h4>Sin resultados...</h4>
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                    </div>
                </div>
            </div>
            {/* modal segundo abre */}
            <div className="modal fade" id="modal-inventario" tabIndex="-1" role="dialog" aria-labelledby="modal-inventarioCenterTitle" aria-hidden="true">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Captura evidenvias</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group">
                                    <label htmlFor="cattipomovimiento_id">Tipo movimiento*</label>
                                    {cargandotp ?
                                        "Cargando"
                                        :
                                        <Controller
                                            name="cattipomovimiento_id"
                                            control={control}
                                            render={({ onChange, onBlur }) => (
                                                <Select
                                                    placeholder='--Selecciona un valor--'
                                                    onChange={handleChangeTipoMov}
                                                    options={tiposmovimiento}
                                                    isDisabled={false}

                                                />
                                            )}
                                        />
                                    }
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-6">
                                        <label htmlFor="idAlmacen">Almacen*</label>
                                        <select className="form-control" name="idAlmacen" id="idAlmacen" ref={register({ required: true })} >
                                            {
                                                almacenes.length > 0 ?
                                                    almacenes.map((object, index) => (
                                                        ajuste ?
                                                            ajuste.idAlmacen == object.value ?
                                                                <option value={object.value} key={index} selected>{object.label}</option>
                                                                :
                                                                <option value={object.value} key={index}>{object.label}</option>
                                                            :
                                                            <option value={object.value} key={index}>{object.label}</option>
                                                    ))
                                                    :
                                                    ""
                                            }
                                        </select>
                                        {errors.catalmacen_id &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="Cantidad">Producto*</label>
                                        <label className="form-control" >
                                            {
                                                ajuste ?
                                                    ajuste.Nombre
                                                    :
                                                    ""
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Cantidad">Cantidad*</label>
                                    <input type="number" className="form-control" id="Cantidad" name="Cantidad" ref={register({ required: true, pattern: /^\d*(\.\d{1})?\d{0,1}$/i })} />
                                    {errors.Cantidad &&
                                        <div className="error-form text-red p-small">
                                            Este campo no puede estar vacío y debé ser un campo numérico!
                                        </div>
                                    }
                                </div>
                                <div id="respuesta-ajuste" style={{ fontSize: "11px" }} ></div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" id="enviar">Guardar</button>
                                <button type="button" className="btn btn-secondary" id="cerrar-mocal-cliente" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* modal segundo cierra */}
        </>


    )
}

export default Inventario;