import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const EditarConcepto =() => {
    const URLCONCEPTOLEER = 'auth/nominas/conceptos-mostrar/'
    let { id } = useParams();
    const concepto  =obtenerData(URLCONCEPTOLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(concepto) =>{
            setValue('Nombre', concepto.Nombre)
            setValue('Tipo', concepto.Tipo)
            setValue('Descripcion', concepto.Descripcion)
            }
        asignar(concepto)
        }
    ,[setValue,concepto]);
    
    return (
    <>
    <Titulo titulo="Ver concepto" tituloBajo="Conceptos &gt; Editar Concepto"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Unidades Clave</h4>
                </div> */}
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre del concepto*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Tipo">Tipo*</label>
                            <select className="form-control" name="Tipo" id="Tipo" ref={register({ required: true })}  disabled="true" >
                                <option value="P">Positivo</option>
                                <option value="N">Negativo</option>
                            </select>
                            {errors.Tipo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripcion*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/conceptos" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarConcepto;