import React, {useState, useEffect} from 'react';
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import history from '../../librerias/history';
import { useForm,Controller  } from 'react-hook-form'
import funciones from '../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import Select from 'react-select';
import obtenerCatalogo from './../../librerias/obtenerCatalogo'


  
const AgregarPermiso =() => {
    const [roles,setRoles] = useState({})
    const [modulos,setModulos] = useState({})
    const [cargando,setCargado] = useState(true)
    const [cargandoM,setCargadoM] = useState(true)
    const { control,register, handleSubmit,setValue  } = useForm()
    

    const traerRoles =async () => {
        const roles= await obtenerCatalogo('auth/admin/roles-catalogo')
        setRoles(roles);
        setCargado(false)
        setValue("catrol_id",{value: null, label: '--Selecciona un valor--'})
        
    }
    const traerModulos =async () => {
        const modulos= await obtenerCatalogo('auth/admin/modulos-catalogo')
        setModulos(modulos);
        setCargadoM(false)
        setValue("catmodulo_id",{value: null, label: '--Selecciona un valor--'})
      }

    useEffect(
        ()=>{
            traerRoles();
            traerModulos();

            
        },[setValue]
    )

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            if(data.catmodulo_id.value && data.catrol_id.value){
                data.catmodulo_id = data.catmodulo_id.value;
                data.catrol_id = data.catrol_id.value;
                const response =  await API.post('auth/admin/permisos-agregar', data);
                if(response.status===201){
                   swal({
                        title: "Registrado",
                        text: "El permiso se ha registrado correctamente",
                        icon: "success",
                        button: "Aceptar"
                   }); 
                    document.getElementById("enviar").disabled = false;
                   history.push('/permisos')
                }else{
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response,document.getElementById("respuesta"));
                }
            }else{
                document.getElementById("enviar").disabled = false;

                document.getElementById("respuesta").innerHTML ="Favor de seleccionar un rol y un módulo";
            }


          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    const handleChangeRol =  e => {
        setValue("catrol_id", e);
        // setValue("encargado", e.value);
    }
    const handleChangeModulo = e => {
        setValue("catmodulo_id", e);
        
        // setValue("encargado", e.value);
    }
    
    return (
    <>
    <Titulo titulo="Agregar permisos" tituloBajo="Permisos &gt; Agregar permisos"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Permisos</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="rol">Rol*</label>
                            {cargando ?
                            "Cargando"
                            :
                            <Controller
                                name="catrol_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                      onChange={handleChangeRol}
                                        options={roles}
                                        isDisabled={false}

                                    />
                                )}
                            />
                            }
                            {/* <Select 
                            //   value={encargado}
                            placeholder="seleccione un valor"   
                            options={roles} 
                            onChange={handleChangeRoles}
                            name="rol"
                            className="custom-select select-react"
                            /> */}
                            {/* <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  /> */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Módulo*</label>
                            {cargandoM ?
                            "Cargando"
                            :
                            <Controller
                                
                            name="catmodulo_id"
                            control={control}
                            render={({ onChange, onBlur }) => (
                                <Select
                                placeholder='--Selecciona un valor--'
                                  onChange={handleChangeModulo}
                                  options={modulos}

                                />
                            )}
                            />
                            }
                        </div>
                        <div className="form-group row mt-5">
                            <div className="col-lg-3">
                                <div className="mt-3">
                                    <div className="styled-checkbox">
                                        <input type="checkbox" name="Crear" id="Crear"  ref={register} />
                                        <label htmlFor="Crear">Crear</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="mt-3">
                                    <div className="styled-checkbox">
                                        <input type="checkbox" name="Actualizar" id="ActualizarC"  ref={register} />
                                        <label htmlFor="ActualizarC">Actualizar</label>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-lg-3">
                                <div className="mt-3">
                                    <div className="styled-checkbox">
                                        <input type="checkbox" name="Visualizar" id="VisualizarC"  ref={register} />
                                        <label htmlFor="VisualizarC">Visualizar</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="mt-3">
                                    <div className="styled-checkbox">
                                        <input type="checkbox" name="Eliminar" id="EliminarC"  ref={register} />
                                        <label htmlFor="EliminarC">Eliminar</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        
                        <div className="text-right">
                            <Link to="/permisos" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarPermiso;