import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const VerTipoMovimiento =() => {
    const URLTIPOMOVIMIENTOLEER = 'auth/movimientos/tipomovimientos-mostrar/'
    let { id } = useParams();
    const TipoMovimiento  =obtenerData(URLTIPOMOVIMIENTOLEER,id,1)
    const { register, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(TipoMovimiento) =>{
            setValue('Nombre', TipoMovimiento.Nombre)
            setValue('Tipo', TipoMovimiento.Tipo)
            setValue('Descripcion', TipoMovimiento.Descripcion)
            }
        asignar(TipoMovimiento)
        }
    ,[setValue,TipoMovimiento]);

    return (
    <>
    <Titulo titulo="Ver tipo de moviemnto" tituloBajo="Tipos de movimientos &gt; Editar tipo de movimiento"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Categoria producto</h4>
                </div> */}
                <div className="widget-body" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Tipo">Tipo*</label>
                            <select className="form-control" name="Tipo" id="Tipo" ref={register({ required: true })} >
                                <option value="Entrada">Entrada</option>
                                <option value="Salida">Salida</option>
                            </select>
                            {errors.Tipo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-formz text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/tiposmovimiento" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerTipoMovimiento;