import React, {useState,useEffect}from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller  } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import Select from 'react-select';
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'

const AgregarSucursal =() => {
    const { register, handleSubmit, errors,setValue,control } = useForm()
    const [empleados,setEmpleado] = useState({})
    const [cargando,setCargado] = useState(true)

    
    const traerEmpleados =async () => {
        const empleados= await obtenerCatalogo('auth/nominas/empleados-catalogo')
        setEmpleado(empleados);
        setCargado(false)
        setValue("catempleado_id",{value: null, label: '--Selecciona un valor--'})
    }
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            if(data.catempleado_id.value){
                data.catempleado_id = data.catempleado_id.value;
            }else{
                data.catempleado_id = null;

            }
            const response =  await API.post('auth/sucursales/sucursales-agregar', data);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "La sucursal se ha registrado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/sucursales')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    useEffect(
        ()=>{
            traerEmpleados();
        },[setValue]
    )
    const handleChangeEmpleado = e =>{
        setValue("catempleado_id", e);
    }
    return (
    <>
    <Titulo titulo="Agregar sucursal" tituloBajo="Scursal &gt; Agregar sucursal"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Sucursal</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true, maxLength: 60 })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y su longitud no puede ser mayor a 60!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="rol">Encargado*</label>
                            {cargando ?
                            "Cargando"
                            :
                            <Controller
                                name="catempleado_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                      onChange={handleChangeEmpleado}
                                        options={empleados}
                                        isDisabled={false}

                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Telefono">Teléfono*</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true, maxLength: 40 })}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                                 Favor de llenar el campo
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Dirección*</label>
                            <input type="text" className="form-control" id="Direccion" name="Direccion" ref={register({ required: true })}  />
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y su longitud no puede ser mayor a 50!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/sucursales" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarSucursal;