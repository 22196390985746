import React, { useEffect } from 'react';
import {    Link, NavLink  } from "react-router-dom";
// import API from '../librerias/api'
import obtenerModulos from './MenuLatetalLogica'
// import funciones from '../librerias/funciones'
// import logo from './../../logo.png'; 
// import cara from './../../cara.png'; 
// import logoBig from './../../logo-big.png'; 
// import'./header.css'; 



const MenuLateral =() => {
    const { cargando, data:categorias  } =obtenerModulos();
    return (

        <div className="default-sidebar">
            <nav className="side-navbar pl-2 box-scroll" >
                { cargando ?
                <h5>Cargando</h5>
                :
                <>
                {
                    categorias.length > 0 ?
                    <ul className="list-unstyled">
                        {
                            categorias.map((categoria, index) => (
                                <li key={index}>
                                {
                                // si es una categoria solo con un modulo y  si es un modulo que debe estar solo, imprimos el puro menu
                                categoria.cat_modulos.length=== 1 && categoria.cat_modulos[0].ModuloSolo===1?
                                    <Link to={categoria.cat_modulos[0].Ruta}><i className="demo-icon" dangerouslySetInnerHTML={{__html: categoria.cat_modulos[0].FontAwesome}} ></i><span>{categoria.cat_modulos[0].NombreModulo}</span></Link>
                                :    
                                <>
                                    <a href={`#${categoria.IdModuloCategorias}`} aria-expanded="false" data-toggle="collapse"><i className="demo-icon"  dangerouslySetInnerHTML={{__html: categoria.FontAwesome}}></i><span>{categoria.NombreCategoria}</span></a>
                                    <ul id={categoria.IdModuloCategorias} className="collapse list-unstyled pt-0">
                                    {
                                        categoria.cat_modulos.map((modulo, indexm) => (
                                            <li key={indexm} ><Link to={modulo.Ruta}><i className="demo-icon"  dangerouslySetInnerHTML={{__html: modulo.FontAwesome}}></i>  {modulo.NombreModulo}</Link></li>
                                        ))
                                    }
                                    </ul>
                                    <li className="linea" ></li>
                                </>
                                }
                                </li>
                            ))

                        }
                    </ul>
                    :
                    <div className="text-center">
                        <h4>Sin modulos aasignados</h4>
                    </div>                 
                }
                </>
                }
            {/* <ul className="list-unstyled">
                    <li><a href="#dropdown-db" aria-expanded="falsfe" data-toggle="collapse"><i className="la la-columns"></i><span>Dashboard</span></a>
                        <ul id="dropdown-db" className="collapse list-unstyled pt-0">
                            <li><a href="db-default.html">Default</a></li>
                            <li><a href="db-clean.html">Clean</a></li>
                            <li><a href="db-compact.html">Compact</a></li>
                            <li><a href="db-modern.html">Modern</a></li>
                            <li><a href="db-social.html">Social</a></li>
                            <li><a href="db-smarthome.html">Smarthome</a></li>
                            <li><a href="db-all.html">All</a></li>
                        </ul>
                    </li>

                    <li><a href="components-widgets.html"><i className="la la-spinner"></i><span>Widgets</span></a></li>
                </ul>
                
                <ul className="list-unstyled">
                    <li className="active"><a href="#dropdown-ui" aria-expanded="true" data-toggle="collapse"><i className="la la-share-alt"></i><span>UI Elements</span></a>
                        <ul id="dropdown-ui" className="collapse list-unstyled show pt-0">
                            <li><a href="components-buttons.html">Buttons</a></li>
                            <li><a href="components-alerts.html">Alerts</a></li>
                            <li><a href="components-modal.html">Modal</a></li>
                            <li><a href="components-notifications.html">Noty</a></li>
                            <li><a href="components-timeline.html">Timeline</a></li>
                            <li><a href="components-progress.html">Progress</a></li>
                            <li><a href="components-tabs.html">Tabs</a></li>
                            <li><a href="components-charts.html">Chart Js</a></li>
                            <li><a href="components-accordion.html">Accordion</a></li>
                            <li><a href="components-popover.html">Popover</a></li>
                            <li><a href="components-tooltip.html">Tooltip</a></li>
                            <li><a className="active" href="components-scrollable.html">Scrollable</a></li>
                        </ul>
                    </li>

                </ul> */}
                {/* <span className="heading">Pages</span> */}
            </nav>
        </div>

    )
}

export default MenuLateral;