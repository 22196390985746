import React
// , { useEffect,useState} 
from 'react';
//  import history from './../librerias/history'
import { useForm } from 'react-hook-form'
import funciones from './../librerias/funciones'
import API from './../librerias/api'
import './../login.css';
import logo from './../logo.png'; 
import logosisdesa from './../logo_sisdesa_blanco.png'; 

const Login =({cambiarUsuario})=>{
    const { register, handleSubmit, errors  } = useForm()

  

    const onSubmit =async (data,e) => { 
        try {
          const response =  await API.post('auth/login', data);
            if(response.status===200){
             localStorage.setItem("user", JSON.stringify(response.data));
             window.location.replace('');
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
          }
  
       }
    return(
      <div className="container-login">
        <div className="container ">
            <div className="card card-container">
                <img id="profile-img" className="profile-img-card"  src={logo} />
                <form className="form-signin " onSubmit={handleSubmit(onSubmit)}>
                    <span id="reauth-email" className="reauth-email"></span>
                    <label><b>Correo/email</b></label>
                    <input type="email" name="email" ref={register({ required: true })}  className="form-control" placeholder="Correo/Usuario"   />
                    {errors.email &&
                    <div className="error-form text-red p-small">
                      Este campo no puede estar vacío!
                    </div>
                    }
                    <label><b>Contrsaeña</b></label>
                    <input type="password" name="password" className="form-control" placeholder="Password" ref={register({ required: true })}  />
                    {errors.password &&
                    <div className="error-form text-red p-small">
                      Este campo no puede estar vacío!
                    </div>
                    }
                    <div id="respuesta" className="text-red">

                    </div>
                    {/* invalid-feedback */}
                    <div className="row pt-2" >
                      <div className="col-6">
                        <a href="#" className="forgot-password pt-3 p-small">
                        olvidé mi contraseña?
                        </a>
                      </div>
                      <div className="col-6 pt-1">
                          <button className="btn btn-primary mb-2 mr-1" type="submit">Iniciar sesión</button>
                        
                      </div>
                    </div>
                </form>
                <hr></hr>
                <p className="text-center p-small" >
                  Marcas Registradas: Tecnoland Copyright 2004 - 2020
                  <br/>
                  Todos los derechos reservados. Nros. de registro 9.82, 2.112.927
                  <br/>
                  *Si no rer usuario de este sistema favor de abandonar el sitio*
                </p>
            </div>
        </div>
        <div id="logo-sisdesa-login" >
          <div className="bloque-footer-login" >
            <p className="p-footer-login text-white">
             Desarrolado por:
            </p>
          </div>
          <div className="bloque-footer-login" >
            <img id="profile-img" className="img-sisdesa-login"  src={logosisdesa} />
          </div>
        </div>
      </div>
    )
    
  }
  export default Login;
