import React from 'react';
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import history from '../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const AgregarRoles =() => {
    const { register, handleSubmit, errors  } = useForm()

    const onSubmit =async (data,e) => { 
        try {
            // console.log(data)
            document.getElementById("enviar").disabled = true;
            const response =  await API.post('auth/admin/roles-agregar', data);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "El rol se ha registrado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/roles')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Agregar rol" tituloBajo="Roles &gt; Agregar rol"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Rol</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="rol">Rol*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/roles" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarRoles;