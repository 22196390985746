import React, { useState, useEffect } from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import { Link } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import ModalTablaCliente from '../../../componentes/ModalTablaCliente'
// import ModalTablaProductoSinInventario from '../../../componentes/ModalTablaProductoSinInventario'
import obtenerValorcfg from '../../../librerias/obtenerValorcfg'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
// import ModalTablaProducto from '../../../componentes/ModalTablaProducto'
import obtenerData from '../../../librerias/obtenerData'
import { useParams } from "react-router-dom";

import Webcam from "react-webcam";
import red from './img/senal.png';
import telefonomensaje from './img/telefonomensaje.png';
import bocina from './img/bocina.png';
import microfono from './img/micro.png';
import sensormano from './img/sensormano.png';
import touch from './img/touch.png';
import wifi from './img/wifi.png';
import bluetooth from './img/bluetooth.png';
import linterna from './img/linterna.png';
import carga from './img/carga.png';
import playboton from './img/playboton.png';
import camara from './img/camara.png';
import huella from './img/huella.png';

import camaratrasera from './img/camara_trasera.png';
import cargaIna from './img/cargaIna.png';
import facial from './img/facial.png';
import jack from './img/jack.png';
import volumen from './img/volumen.png';


import logo from './../../../logoimp.png';
// const WebcamComponent = () => <Webcam />;
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const VerServicio = () => {
    const clsServicio = {
        catproducto_id: { value: null, name: null },
        Precio: "",
        Cantidad: 1,
        Total: "",
        Almacen: "",
        cantidadMax: 0,
    };
    const clsProducto = {
        catproducto_id: { value: null, name: null },
        Precio: "",
        Cantidad: 1,
        Total: "",
        Almacen: "",
        cantidadMax: 0,
    };
    const { register, handleSubmit, errors, setValue } = useForm()
    const [cliente, setCliente] = useState({ id: null, nombre: null });
    const [empleados, setEmpleados] = useState({})
    const [marcas, setMarcas] = useState({})
    const [imgsrc, setImgsrc] = useState([])
    const webcamRef = React.useRef(null);
    const ivaID = 1;
    const [iva, setiva] = useState(16)
    const [fechaEntrega, setFechaEntrega] = useState(new Date());
    const [serviciosRealizar, setServiciosRealizar] = useState([clsServicio]);
    const [servicioActual, setServicioActual] = useState(0)
    const [serviciohecho, setServiciohecho] = useState(null)
    // const [serviciohecho,setServiciohecho] = useState({folio:foliod,qr:qrd})
    const [productos, setProductos] = useState([clsProducto]);
    const [productoActual, setProductoActual] = useState(0)
    const [conceptosp, setConceptosp] = useState([]);
    const [anticipo, setAnticipo] = useState(0);
    const [anticipoDecimal, setAnticipoDecimal] = useState(0);
    const URLSERVICIOTICKET = "auth/servicios/ticket/"
    const URLSERVICIOLEER = 'auth/servicios/servicios-mostrar/'
    let { id } = useParams();
    const servicio = obtenerData(URLSERVICIOLEER, id, 1)


    const calcularTotal = (produc) => {
        return produc.Cantidad * produc.Precio;
    }

    // anticipo
    const validarCambiarAnticipoDe = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            setAnticipoDecimal(valor)
        }
    }
    const validarCambiarAnticipo = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            setAnticipo(valor)
        }
    }

    // conceptosp
    const limpiarConcepto = () => {
        var concep = document.getElementById("concepto")
        var marcav = document.getElementById("concepto-marca")
        var preciov = document.getElementById("concepto-precio")
        var id = document.getElementById("concepto-id")
        concep.readOnly = false;
        marcav.disabled = false;
        preciov.readOnly = false;
        id.readOnly = false;
        concep.value = "";
        marcav.value = "";
        preciov.value = "";
        id.value = 0;
    }
    const addConceptos = () => {
        // validar decimal 
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;

        var pError = document.getElementById("error-concepto");
        pError.innerHTML = "";

        var concep = document.getElementById("concepto").value
        var marcav = document.getElementById("concepto-marca").value
        var preciov = document.getElementById("concepto-precio").value
        var idV = document.getElementById("concepto-id").value
        if (concep === "") {
            pError.innerHTML = "El concepto no puede estar vacío";
            return;
        } else if (marcav === "" || marcav === null) {
            pError.innerHTML = "Debes seleccionar una marca";
            return;
        } else if (!RE.test(preciov) || preciov === "") {
            pError.innerHTML = "El precio debe ser un número con max. dos decimales";
            return;
        }

        const clsConceptosp = {
            id: idV,
            concepto: concep,
            marca: marcav,
            Precio: Number(preciov),
        };
        setConceptosp(conceptosp => [...conceptosp, clsConceptosp]);
    };

    const removeConceptos = indexx => () => {
        setConceptosp(conceptosp => [...conceptosp.filter((concepto, index) => (indexx !== index))]);
    };
    const escogerConcepto = object => {
        var concep = document.getElementById("concepto")
        var marcav = document.getElementById("concepto-marca")
        var preciov = document.getElementById("concepto-precio")
        var id = document.getElementById("concepto-id")
        concep.readOnly = true;
        marcav.disabled = false;
        preciov.readOnly = true;
        id.readOnly = true;
        concep.value = object.Nombre;
        marcav.value = object.catmarca_id;
        preciov.value = object.Precio;
        id.value = object.id;
    }
    // conceptos end 
    //productos
    const handleCambiarPro = async (valor, concepto, indexx) => {
        try {
            var productosNuevos = [];
            var RE = /^\d*\.?\d*$/;

            if (!RE.test(valor) && concepto !== "seleccion") {
                return false;
            }
            // vemos si hay un producto igual en el arreglo
            document.getElementById("error-agregar-prod").innerHTML = "";


            switch (concepto) {
                case "Precio":
                    productosNuevos = productos.map((producto, index) => {

                        if (indexx === index) {
                            producto.Precio = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;

                    })
                    break;
                case "Cantidad":
                    productosNuevos = productos.map((producto, index) => {

                        if (indexx === index && Number(valor) <= Number(producto.cantidadMax)) {
                            producto.Cantidad = valor
                            producto.Total = calcularTotal(producto)
                        }

                        return producto;

                    })
                    break;
                case "seleccion":
                    var found = productos.find(producto => {
                        return producto.catproducto_id.value === valor.id && producto.Almacen === valor.idAlmacen

                    });
                    if (found) {
                        document.getElementById("error-agregar-prod").innerHTML = "Producto ya en la lista";
                        return false;
                    }
                    productosNuevos = productos.map((producto, index) => {

                        if (indexx === index) {
                            producto.catproducto_id = { value: valor.id, name: valor.Codigo + '|' + valor.Nombre }
                            producto.Precio = valor.Precio
                            producto.Cantidad = 1
                            producto.Almacen = valor.idAlmacen
                            producto.cantidadMax = valor.total
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;

                    })
                    break;
                default:
                    break;
            }
            setProductos([...productosNuevos]);
        } catch (error) {
            console.log("error", error)
        }
    }
    const addProducto = () => {
        const clsProducto = {
            catproducto_id: { value: null, name: null },
            Precio: "",
            Cantidad: 1,
            Total: "",
            Almacen: "",
            cantidadMax: 0,
        };
        setProductos(productos => [...productos, clsProducto]);
    };
    const removeProducto = indexx => () => {
        setProductos(productos => [...productos.filter((producto, index) => (indexx !== index))]);

    };
    const onChangleSeleccionarProducto = (data) => {
        handleCambiarPro(data, "seleccion", productoActual)
    }
    //productos fianal
    // servicios

    const handleCambiarServ = async (valor, concepto, indexx) => {
        try {
            var serviciosNuevos = [];
            var RE = /^\d*\.?\d*$/;

            if (!RE.test(valor) && concepto !== "seleccion") {
                return false;
            }
            // vemos si hay un producto igual en el arreglo
            document.getElementById("error-agregar-serv").innerHTML = "";
            switch (concepto) {
                case "Precio":
                    serviciosNuevos = serviciosRealizar.map((servicio, index) => {

                        if (indexx === index) {
                            servicio.Precio = valor
                            servicio.Total = calcularTotal(servicio)
                        }
                        return servicio;

                    })
                    break;
                case "Cantidad":
                    serviciosNuevos = serviciosRealizar.map((servicio, index) => {

                        if (indexx === index && Number(valor) <= Number(servicio.cantidadMax)) {
                            servicio.Cantidad = valor
                            servicio.Total = calcularTotal(servicio)
                        }

                        return servicio;

                    })
                    break;
                case "seleccion":
                    var found = serviciosRealizar.find(servicio => {
                        return servicio.catproducto_id.value === valor.id && servicio.Almacen === valor.idAlmacen

                    });
                    if (found) {
                        document.getElementById("error-agregar-serv").innerHTML = "Producto ya en la lista";
                        return false;
                    }
                    serviciosNuevos = serviciosRealizar.map((servicio, index) => {

                        if (indexx === index) {
                            servicio.catproducto_id = { value: valor.id, name: valor.Codigo + '|' + valor.Nombre }
                            servicio.Precio = valor.Precio
                            servicio.Cantidad = 1
                            servicio.Almacen = valor.idAlmacen
                            servicio.cantidadMax = valor.total
                            servicio.Total = calcularTotal(servicio)
                        }
                        return servicio;

                    })
                    break;
                default:
                    break;
            }
            setServiciosRealizar([...serviciosNuevos]);
        } catch (error) {
            console.log("error", error)
        }
    }
    const onChangleSeleccionarServicio = (data) => {
        handleCambiarServ(data, "seleccion", servicioActual)
    }
    const addServicios = () => {
        const clsServicio = {
            catproducto_id: { value: null, name: '' },
            Precio: "",
            Cantidad: 1,
            Total: "",
            Almacen: "",
            cantidadMax: 0,
        };
        setServiciosRealizar(serviciosRealizar => [...serviciosRealizar, clsServicio]);
    };
    const removeServicio = indexx => () => {
        console.log(indexx)
        setServiciosRealizar(serviciosRealizar => [...serviciosRealizar.filter((servicio, index) => (indexx !== index))]);
    };
    // end servicios
    const traerIVA = async () => {
        const iva = await obtenerValorcfg('auth/cfg-ver/' + ivaID)
        setiva(iva.Valor);

    }
    const traerMarcas = async () => {
        const marcas = await obtenerCatalogo('auth/inventarios/marcas-catalogo')
        if (marcas || marcas.length === 0) {
            setMarcas(marcas);
        } else {
            setMarcas({ value: "", label: '--Selecciona un valor--' })
        }

    }
    const traerEmpleados = async () => {
        const empleados = await obtenerCatalogoSinDefecto('auth/nominas/empleados-catalogo-sucursal')
        if (empleados || empleados.length === 0) {
            empleados.unshift({ value: "", label: '--Selecciona un valor--' })
            setEmpleados(empleados);
        } else {
            setEmpleados({ value: "", label: '--Selecciona un valor--' })
        }
    }
    const onSubmit = async (data, e) => {
        try {
            document.getElementById("respuesta").innerHTML = ""
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("formularioServicio"));
            var imgNum = 1;
            imgsrc.forEach((element, index) => {
                // Split the base64 string in data and contentType
                var block = element.split(";");
                // Get the content type of the image
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                // Convert it to a blob to upload
                var blob = funciones.b64toBlob(realData, contentType);
                // imgArregloBob.push(blob);
                formData.append('image[]', blob, 'imgNum' + (imgNum++) + '.jpg');
            })
            // var files = new File(imgArregloBob, "image[]");
            // formData.append("image[]", files);
            var anticipoValor = Number(anticipo + "." + anticipoDecimal);
            var totalPartidas = document.getElementById("total-cuenta").innerHTML;

            if (anticipoValor < (totalPartidas * .20)) {
                document.getElementById("respuesta").innerHTML = "El anticipo tiene que ser mayor del 20% del total"
                document.getElementById("enviar").disabled = false;

                return;
            }
            formData.append("catcliente_id", cliente.id);
            serviciosRealizar.forEach((element) => {
                if (element.catproducto_id.value !== null)
                    formData.append("serviciosRealizar[]", JSON.stringify(element))
            })
            productos.forEach((element) => {
                if (element.catproducto_id.value !== null)
                    formData.append("productos[]", JSON.stringify(element))
            })
            conceptosp.forEach((element) => (formData.append("conceptos[]", JSON.stringify(element))))
            if (cliente.id == null) {
                document.getElementById("respuesta").innerHTML = "Favor de seleccionar un cliente"
                document.getElementById("enviar").disabled = false;
                return;
            }
            if (formData.getAll('serviciosRealizar[]').length < 1) {
                document.getElementById("respuesta").innerHTML = "Favor de seleccionar minimo un servicio"
                document.getElementById("enviar").disabled = false;
                return;
            }
            formData.append('Anticipo', anticipoValor);

            // formData.getAll('username');
            const response = await API.post('auth/servicios/servicios-agregar', formData);
            if (response.status === 201) {

                swal({
                    title: "Registrado",
                    text: response.data.Mensaje[0],
                    icon: "success",
                    button: "Aceptar"
                });
                setServiciohecho({ folio: response.data.Mensaje[1], qr: response.data.Mensaje[2] })
                //    document.getElementById("enviar").disabled = false;
                //    history.push('/CategoriaProductos')
            } else {
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response, document.getElementById("respuesta"));
            }
            document.getElementById("enviar").disabled = false;
        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
            if (document.getElementById("enviar"))
                document.getElementById("enviar").disabled = false;
        }

    }
    const removImgSer = indexx => () => {
        setImgsrc(imgsrc => [...imgsrc.filter((img, index) => (indexx !== index))]);
    };



    const capture = React.useCallback(
        () => {
            setImgsrc(imgsrc => [...imgsrc, webcamRef.current.getScreenshot()]);
        },
        [webcamRef]
    );


    const imprimir = async (folio) => {
        try {
            const response = await API.get(URLSERVICIOTICKET + folio);
            // console.log(response)
            if (response.status === 200) {
                var servicio = response.data;
                funciones.imprimirServicio(servicio, logo);
            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));
            }

        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"), error);
        }

    }
    useEffect(
        () => {
            const asignar = (servicio) => {
                if (servicio.id) {
                    setValue('Entero', servicio.Entero)
                    setValue('cliente', servicio.cat_cliente.Nombre)
                    setValue('Telefono', servicio.Telefono)
                    setValue('Domicilio', servicio.Domicilio)
                    setValue('empleado', servicio.cat_empleado.Nombre)
                    setValue('Marca', servicio.cat_marca.Nombre)
                    setValue('TFalla', servicio.cat_falla.Nombre)
                    setValue('Modelo', servicio.Modelo)
                    setValue('IMEI', servicio.IMEI)
                    setValue('Falla', servicio.Falla)
                    setValue('Otro', servicio.Otro)
                    setValue('Password', servicio.Password)
                    setValue('Nota', servicio.Nota)
                    funciones.escogerValorRadio("Entregado", servicio.Entregado)
                    funciones.escogerValorRadio("Senal", servicio.Senal)
                    funciones.escogerValorRadio("Microfono", servicio.Microfono)
                    funciones.escogerValorRadio("Sensormano", servicio.Sensormano)
                    funciones.escogerValorRadio("Touch", servicio.Touch)
                    funciones.escogerValorRadio("Wifi", servicio.Wifi)
                    funciones.escogerValorRadio("Bluetooth", servicio.Bluetooth)
                    funciones.escogerValorRadio("Carga", servicio.Carga)
                    funciones.escogerValorRadio("Camara", servicio.Camara)
                    funciones.escogerValorRadio("Huella", servicio.Huella)
                    funciones.escogerValorRadio("CargaIna", servicio.CargaIna)
                    funciones.escogerValorRadio("Facial", servicio.Facial)
                    funciones.escogerValorRadio("Jack", servicio.Jack)
                    funciones.escogerValorRadio("Volumen", servicio.Volumen)
                    funciones.escogerValorRadio("Reparado", servicio.Reparado)
                    funciones.escogerValorRadio("Entregado", servicio.Entregado)
                    funciones.escogerValorRadio("Funda", servicio.Funda)
                    funciones.escogerValorRadio("Sim", servicio.Sim)
                    funciones.escogerValorRadio("Microsd", servicio.Microsd)
                    funciones.escogerValorRadio("AperturaPantallaOTapa", servicio.AperturaPantallaOTapa)
                    funciones.escogerValorRadio("ReparadoAntes", servicio.ReparadoAntes)
                    funciones.escogerValorRadio("Auricular", servicio.Auricular)
                    funciones.escogerValorRadio("ReparacionRiesgosa", servicio.ReparacionRiesgosa)
                    funciones.escogerValorRadio("BotonHome", servicio.BotonHome)
                    funciones.escogerValorRadio("Selfie", servicio.Selfie)
                    funciones.escogerValorRadio("BrilloDisplay", servicio.BrilloDisplay)
                    funciones.escogerValorRadio("Altavoz", servicio.Altavoz)
                    funciones.escogerValorRadio("Altavoz", servicio.Altavoz)
                    funciones.escogerValorRadio("Noresponsbles", servicio.Noresponsbles)
                    if (servicio.Estatus === "4A" && servicio.Reparado === 0) {
                        document.getElementById("Estatus").value = "4B"
                    } else {
                        document.getElementById("Estatus").value = servicio.Estatus
                    }
                    if (servicio.Estatus === "5A") {
                        document.getElementById("div-reparado").classList.remove("d-none");
                    }
                    document.getElementById("Reparado").value = servicio.Reparado
                    document.getElementById("Dispositivo").value = servicio.Dispositivo
                    setFechaEntrega(new Date(servicio.FechaEntrega.replace(/-/g, '\/')))
                    document.querySelectorAll("#formularioServicio input").forEach(element => element.disabled = true)
                    document.querySelectorAll("#formularioServicio select").forEach(element => element.disabled = true)
                    // Patron1
                    // document.querySelectorAll('input[name="Entregado"]')

                    // console.log(servicio)
                }
            }
            asignar(servicio)
            // traerEmpleados();
            // traerIVA();
            // traerMarcas();
        }
        , [servicio]);
    return (
        <>
            <Titulo titulo="Ver servicio" tituloBajo="Servicios &gt; Ver servicio"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="row" >
                            <div className="widget-header bordered no-actions d-flex align-items-center justify-content-between">
                                {/* <h4>Datos de contrato de servicio(s)</h4> */}
                                {
                                    servicio.id ?
                                        <h4 data-toggle="modal" data-target="#modal-qr" style={{ cursor: "pointer", color: "#2c304d" }}>#{servicio.FOLIO}</h4>
                                        :
                                        ""
                                }
                            </div>
                            {/* <div className=" bordered no-actions d-flex align-items-center text-right">
                    {
                        servicio.id?
                        <h4 data-toggle="modal" data-target="#modal-qr" style={{cursor:"pointer"}}>#{servicio.FOLIO}</h4>
                        :
                        ""
                    }
                    </div> */}
                        </div>
                        <div className="widget-body" >
                            <form onSubmit={handleSubmit(onSubmit)} id="formularioServicio">
                                <div className="form-group" >
                                    <label htmlFor="catsucursal_id">Cliente*</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="cliente" readOnly={true} value={cliente.nombre} ref={register({ required: true })} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter" type="button">Buscar</button>
                                        </div>
                                        {errors.cliente &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row" >
                                    <div className="col-6" >
                                        <label>¿Cómo se enteró?</label>
                                        <select className="form-control" name="Entero" id="Entero" ref={register({ required: true })} >
                                            <option value="Pa">Pasando</option>
                                            <option value="Re">Recomendacion</option>
                                            <option value="We">Página web</option>
                                            <option value="Red">Facebook</option>
                                            <option value="Ins">Instagram </option>
                                            <option value="Linke">LinkedIn </option>
                                            <option value="Vol">Volante </option>
                                            <option value="BusG">Búsqueda google </option>
                                            <option value="BNI">BNI </option>
                                            <option value="YVA">Ya he venido anteriormente </option>
                                            <option value="CE">Convenio empresarial</option>
                                            <option value="NA">NA</option>
                                        </select>
                                    </div>
                                    <div className="col-6" >
                                        <label htmlFor="Telefono">Teléfono para seguimiento*</label>
                                        <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register} />
                                        {errors.Telefono &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row" >
                                    <div className="col-6" >
                                        <label htmlFor="Domicilio">Domicilio*</label>
                                        <input type="text" className="form-control" id="Domicilio" name="Domicilio" ref={register} />
                                        {errors.Telefono &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                    <div className="col-6" >
                                        <div className="form-group">
                                            <label htmlFor="empleado">Colaborador*</label>
                                            <input type="text" className="form-control" id="empleado" name="empleado" ref={register} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row" >
                                    <div className="col-6" >
                                        <div className="form-group">
                                            <label htmlFor="catmarca_id">Marca*</label>
                                            <input type="text" className="form-control" id="Marca" name="Marca" ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-6" >
                                        <label>Dispositivo</label>
                                        <select className="form-control" name="Dispositivo" id="Dispositivo" ref={register({ required: true })} >
                                            <option value="C">Celular</option>
                                            <option value="TA">Tablet Android</option>
                                            <option value="I">Ipad</option>
                                            <option value="CG">Consola Gamer</option>
                                            <option value="LA">Laptop</option>
                                            <option value="IP">Impresora</option>
                                            <option value="R3">Reproductor mp3</option>
                                            <option value="SW">Smartwatch</option>
                                            <option value="B">Bocina</option>
                                            <option value="AU">Audifonos</option>
                                            <option value="O">Otro...</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-6" >
                                        <label htmlFor="Modelo">Modelo*</label>
                                        <input type="text" className="form-control" id="Modelo" name="Modelo" ref={register({ required: true })} />
                                        {errors.Modelo &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                    <div className="col-6" >
                                        <label htmlFor="IMEI">IMEI/Serie*(Últimos 6 digitos)</label>
                                        <input type="text" className="form-control" id="IMEI" name="IMEI" ref={register({ required: true })} />
                                        {errors.IMEI &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row" >
                                    <div className="col-6" >
                                        <div className="form-group">
                                            <label htmlFor="TFalla">Tipo Falla</label>
                                            <input type="text" className="form-control" id="TFalla" name="TFalla" ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-6" >
                                        <label htmlFor="Falla">Falla*</label>
                                        <input type="text" className="form-control" id="Falla" name="Falla" ref={register({ required: true })} />
                                        {errors.Falla &&
                                            <div className="error-form text-red p-small">
                                                Este campo no puede estar vacío!
                                            </div>
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div className="form-row row">
                                    <div className="col-12" >
                                        <h3>Detalles del equipo</h3>
                                    </div>
                                    {/* empieza */}
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Señal</label>
                                        <div className="switch-field">
                                            <img src={red} alt="Senal" title="Señal" />
                                            <input type="radio" id="Senal" name="Senal" value="1" defaultChecked={true} />
                                            <label htmlFor="Senal">SI</label>
                                            <input type="radio" id="Senal2" name="Senal" value="0" />
                                            <label htmlFor="Senal2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Volumen + y -</label>
                                        <div className="switch-field">
                                            <img src={volumen} alt="Volumen" />
                                            <input type="radio" id="Volumen" name="Volumen" value="1" defaultChecked={true} />
                                            <label htmlFor="Volumen">SI</label>
                                            <input type="radio" id="Volumen2" name="Volumen" value="0" />
                                            <label htmlFor="Volumen2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Botones (Home, Bloqueo)</label>
                                        <div className="switch-field">
                                            <img src={playboton} alt="Playboton" />
                                            <input type="radio" id="Playboton" name="BotonHome" value="1" defaultChecked={true} />
                                            <label htmlFor="Playboton">SI</label>
                                            <input type="radio" id="Playboton2" name="BotonHome" value="0" />
                                            <label htmlFor="Playboton2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Carga inalambrica</label>
                                        <div className="switch-field">
                                            <img src={cargaIna} alt="CargaIna" />
                                            <input type="radio" id="CargaIna" name="CargaIna" value="1" defaultChecked={true} />
                                            <label htmlFor="CargaIna">SI</label>
                                            <input type="radio" id="CargaIna2" name="CargaIna" value="0" />
                                            <label htmlFor="CargaIna2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Auricular</label>
                                        <div className="switch-field">
                                            <img src={telefonomensaje} alt="Telefonomensaje" />
                                            <input type="radio" id="Telefonomensaje" name="Auricular" value="1" defaultChecked={true} />
                                            <label htmlFor="Telefonomensaje">SI</label>
                                            <input type="radio" id="Telefonomensaje2" name="Auricular" value="0" />
                                            <label htmlFor="Telefonomensaje2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Wifi</label>
                                        <div className="switch-field">
                                            <img src={wifi} alt="Wifi" />
                                            <input type="radio" id="Wifi" name="Wifi" value="1" defaultChecked={true} />
                                            <label htmlFor="Wifi">SI</label>
                                            <input type="radio" id="Wifi2" name="Wifi" value="0" />
                                            <label htmlFor="Wifi2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Huella</label>
                                        <div className="switch-field">
                                            <img src={huella} alt="Huella" />
                                            <input type="radio" id="Huella" name="Huella" value="1" defaultChecked={true} />
                                            <label htmlFor="Huella">SI</label>
                                            <input type="radio" id="Huella2" name="Huella" value="0" />
                                            <label htmlFor="Huella2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Plug 3.5m</label>
                                        <div className="switch-field">
                                            <img src={jack} alt="Jack" />
                                            <input type="radio" id="Jack" name="Jack" value="1" defaultChecked={true} />
                                            <label htmlFor="Jack">SI</label>
                                            <input type="radio" id="Jack2" name="Jack" value="0" />
                                            <label htmlFor="Jack2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Sensor de proximidad</label>
                                        <div className="switch-field">
                                            <img src={sensormano} alt="Sensormano" />
                                            <input type="radio" id="Sensormano" name="Sensormano" value="1" defaultChecked={true} />
                                            <label htmlFor="Sensormano">SI</label>
                                            <input type="radio" id="Sensormano2" name="Sensormano" value="0" />
                                            <label htmlFor="Sensormano2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Bluetooth</label>
                                        <div className="switch-field">
                                            <img src={linterna} alt="Linterna" />
                                            <input type="radio" id="Bluetooth" name="Bluetooth" value="1" defaultChecked={true} />
                                            <label htmlFor="Bluetooth">SI</label>
                                            <input type="radio" id="Bluetooth2" name="Bluetooth" value="0" />
                                            <label htmlFor="Bluetooth2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Reconocimiento Facial</label>
                                        <div className="switch-field">
                                            <img src={facial} alt="Facial" />
                                            <input type="radio" id="Facial" name="Facial" value="1" defaultChecked={true} />
                                            <label htmlFor="Facial">SI</label>
                                            <input type="radio" id="Facial2" name="Facial" value="0" />
                                            <label htmlFor="Facial2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Touch</label>
                                        <div className="switch-field">
                                            <img src={touch} alt="Touch" />
                                            <input type="radio" id="Touch" name="Touch" value="1" defaultChecked={true} />
                                            <label htmlFor="Touch">SI</label>
                                            <input type="radio" id="Touch2" name="Touch" value="0" />
                                            <label htmlFor="Touch2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Altavoz</label>
                                        <div className="switch-field">
                                            <img src={bocina} alt="Bocina" />
                                            <input type="radio" id="Bocina" name="Altavoz" value="1" defaultChecked={true} />
                                            <label htmlFor="Bocina">SI</label>
                                            <input type="radio" id="Bocina2" name="Altavoz" value="0" />
                                            <label htmlFor="Bocina2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Camara</label>
                                        <div className="switch-field">
                                            <img src={camara} alt="Camara" />
                                            <input type="radio" id="Camara" name="Camara" value="1" defaultChecked={true} />
                                            <label htmlFor="Camara">SI</label>
                                            <input type="radio" id="Camara2" name="Camara" value="0" />
                                            <label htmlFor="Camara2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Puerto de carga</label>
                                        <div className="switch-field">
                                            <img src={carga} alt="Carga" />
                                            <input type="radio" id="Carga" name="Carga" value="1" defaultChecked={true} />
                                            <label htmlFor="Carga">SI</label>
                                            <input type="radio" id="Carga2" name="Carga" value="0" />
                                            <label htmlFor="Carga2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Brillo de Display </label>
                                        <div className="switch-field">
                                            <input type="radio" id="BrilloDisplay" name="BrilloDisplay" value="1" defaultChecked={true} />
                                            <label htmlFor="BrilloDisplay">SI</label>
                                            <input type="radio" id="BrilloDisplay2" name="BrilloDisplay" value="0" />
                                            <label htmlFor="BrilloDisplay2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Micrófono</label>
                                        <div className="switch-field">
                                            <img src={microfono} alt="Microfono" />
                                            <input type="radio" id="Microfono" name="Microfono" value="1" defaultChecked={true} />
                                            <label htmlFor="Microfono">SI</label>
                                            <input type="radio" id="Microfono2" name="Microfono" value="0" />
                                            <label htmlFor="Microfono2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Selfie</label>
                                        <div className="switch-field">
                                            <img src={camaratrasera} alt="Camaratrasera" />
                                            <input type="radio" id="Camaratrasera" name="Selfie" value="1" defaultChecked={true} />
                                            <label htmlFor="Camaratrasera">SI</label>
                                            <input type="radio" id="Camaratrasera2" name="Selfie" value="0" />
                                            <label htmlFor="Camaratrasera2" className="no-label" >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="Otro" id="Otro" name="Otro" ref={register} />
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="1" id="Noresponsbles" name="Noresponsbles" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            No nos hacemos responsables de fallas adicionales en equipos que se reciban apagados y no se puedan diagnosticar.
                                        </label>
                                    </div>
                                    {/* termina */}
                                </div>
                                <hr />
                                <div className="form-row">
                                    <h3>Mas detalles del equipo <br /> <small>Recuerda siempre dar la funda , sim y sd al cliente(Llamativa)</small></h3>
                                </div>
                                <div className="form-row row">
                                    <div className="col-12 col-sm-4 col-md-3 radio-div">
                                        <label>Funda</label>
                                        <div className="switch-field">
                                            <input type="radio" id="Funda" name="Funda" value="1" defaultChecked={true} />
                                            <label htmlFor="Funda">SI</label>
                                            <input type="radio" id="Funda2" name="Funda" value="0" />
                                            <label htmlFor="Funda2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3 radio-div">
                                        <label>SIM</label>
                                        <div className="switch-field">
                                            <input type="radio" id="Sim" name="Sim" value="1" defaultChecked={true} />
                                            <label htmlFor="Sim">SI</label>
                                            <input type="radio" id="Sim2" name="Sim" value="0" />
                                            <label htmlFor="Sim2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3 radio-div">
                                        <label>Micro SD</label>
                                        <div className="switch-field">
                                            <input type="radio" id="Microsd" name="Microsd" value="1" defaultChecked={true} />
                                            <label htmlFor="Microsd">SI</label>
                                            <input type="radio" id="Microsd2" name="Microsd" value="0" />
                                            <label htmlFor="Microsd2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 radio-div">
                                        <label>Apertura(Pantalla/tapa)</label>
                                        <div className="switch-field">
                                            <input type="radio" id="AperturaPantallaOTapa" name="AperturaPantallaOTapa" value="1" defaultChecked={true} />
                                            <label htmlFor="AperturaPantallaOTapa">SI</label>
                                            <input type="radio" id="AperturaPantallaOTapa2" name="AperturaPantallaOTapa" value="0" />
                                            <label htmlFor="AperturaPantallaOTapa2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 radio-div">
                                        <label>Reparación riesgosa</label>
                                        <div className="switch-field">
                                            <input type="radio" id="ReparacionRiesgosa" name="ReparacionRiesgosa" value="1" defaultChecked={true} />
                                            <label htmlFor="ReparacionRiesgosa">SI</label>
                                            <input type="radio" id="ReparacionRiesgosa2" name="ReparacionRiesgosa" value="0" />
                                            <label htmlFor="ReparacionRiesgosa2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 radio-div">
                                        <label>Reparado antes</label>
                                        <div className="switch-field">
                                            <input type="radio" id="ReparadoAntes" name="ReparadoAntes" value="1" defaultChecked={true} />
                                            <label htmlFor="ReparadoAntes">SI</label>
                                            <input type="radio" id="ReparadoAntes2" name="ReparadoAntes" value="0" />
                                            <label htmlFor="ReparadoAntes2" className="no-label"  >NO</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-row pt-3">
                                    <div className="col-12 col-sm-8 overflow-auto" >
                                        <div className="col-12" >
                                            <h3>
                                                Toma de evidencias

                                                {/* <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modal-foto" type="button" > +</button> */}
                                            </h3>

                                        </div>
                                        <div style={{ overflow: "scroll", height: "400px" }} >

                                            {
                                                servicio.trn_serviciosfotos ?
                                                    servicio.trn_serviciosfotos.map((elemento, keyIndex) =>
                                                    (
                                                        <div className="p-2 div-img-serv float-left" key={keyIndex} >
                                                            <a target="_blank" href={elemento.img}>
                                                                <img src={elemento.img} alt={elemento.trnservicio_id} ></img>
                                                            </a>
                                                        </div>
                                                    )
                                                    )
                                                    :
                                                    ""

                                            }
                                            {/* <img src={imgfalla} ></img> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4" >
                                        <h3>Patrón o contraseña</h3>
                                        <div className="col-12" >
                                            {
                                                servicio.Patron
                                                    ?
                                                    <img src={`${servicio.urlDocs}${servicio.id}/${servicio.Patron}`} style={{ border: "solid 1px beige", width: "100%" }} />
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div className="col-12" >
                                            <input type="text" className="form-control" id="Password" name="Password" placeholder="Contraseña" ref={register} />
                                        </div>
                                        {/* <table className="table mb-0 table-striped" border="1">
                                    <thead>
                                        <tr>
                                            <td>
                                            <input type="number" className="form-control" id="Patron1" name="Patron1" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron2" name="Patron2" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron3" name="Patron3" ref={register}  />
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                            <input type="number" className="form-control" id="Patron4" name="Patron4" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron5" name="Patron5" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron6" name="Patron6" ref={register}  />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <input type="number" className="form-control" id="Patron7" name="Patron7" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron8" name="Patron8" ref={register}  />
                                            </td>
                                            <td>
                                            <input type="number" className="form-control" id="Patron9" name="Patron9" ref={register}  />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                            <input type="text" className="form-control" id="Password" name="Password" placeholder="Contraseña" ref={register}  />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                    </div>
                                </div>
                                <div className="form-row pt-3"  >
                                    <h2>
                                        Notas de reparación
                                    </h2>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Nota">Comentarios para el técnico(Campo visible para el cliente)</label>
                                    <textarea className="form-control" id="Nota" name="Nota" rows="3" ref={register}></textarea>
                                </div>
                                <hr />
                                <div className="form-row pt-3"  >
                                    <h2>
                                        Servicio(s) a realizar
                                    </h2>
                                </div>
                                <div id="error-agregar-serv">
                                </div>
                                {
                                    servicio.trn_serviciosdetalles ?
                                        servicio.trn_serviciosdetalles.map((object, index) => {
                                            const fieldName = `catservicio_id[${index}]`;
                                            return (
                                                <div className="form-group form-row" key={index}>
                                                    <div className="col-4" >
                                                        <label htmlFor={`${fieldName}.catservicio_id`}>Servicio*</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" readOnly={true} value={object.cat_producto.Nombre} />
                                                        </div>
                                                    </div>
                                                    <div className="col-2" >
                                                        <label htmlFor={`${fieldName}.Precio`}>Precio</label>
                                                        <input type="text" className="form-control" id={'Precio' + index} name={`${fieldName}.Precio`} value={object.Precio} onChange={(e) => { handleCambiarServ(e.target.value, "Precio", index) }} required readOnly />
                                                    </div>
                                                    <div className="col-2" >
                                                        <label htmlFor={`${fieldName}.Cantidad`}>Cant.</label>
                                                        <input type="text" className="form-control" id={'Cantidad' + index} name={`${fieldName}.Cantidad`} value={object.Cantidad} onChange={(e) => { handleCambiarServ(e.target.value, "Cantidad", index) }} required />
                                                    </div>
                                                    <div className="col-2" >
                                                        <label htmlFor={`${fieldName}.Descuento`}>Descuento</label>
                                                        <input type="text" className="form-control" id={'Descuento' + index} name={`${fieldName}.Descuento`} value={object.Descuento} onChange={(e) => { handleCambiarServ(e.target.value, "Descuento", index) }} required />
                                                    </div>
                                                    <div className="col-2" >
                                                        <label htmlFor={`${fieldName}.Total`}>Total</label>
                                                        <input type="text" className="form-control" id={'Total' + index} name={`${fieldName}.Total`} value={object.Subtotal} readOnly={true} required />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        ""
                                }
                                {/* <hr />
                        <div className="form-row pt-3"  >
                            <h2>
                                Productos
                            </h2>
                        </div>
                        <div id="error-agregar-prod">
                        </div>
                        {
                            // trn_productos
                            servicio.trn_productos ?
                            servicio.trn_productos.map((object, index) => {
                                const fieldName = `catproducto_id[${index}]`;
                                return (
                                    <div className="form-group form-row" key={index}>
                                        <div className="col-4" >
                                            <label htmlFor={`${fieldName}.catproducto_id`}>Producto*</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" readOnly={true} value={object.cat_producto.Nombre} /> 
                                            </div>
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Precio`}>Precio</label>
                                            <input  type="text"  className="form-control" id={'Precio'+index} name={`${fieldName}.Precio`} value={object.Precio} onChange={ (e)=>{handleCambiarServ(e.target.value,"Precio",index)}} required readOnly />
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Cantidad`}>Cant</label>
                                            <input  type="text" className="form-control" id={'Cantidad'+index}   name={`${fieldName}.Cantidad`} value={object.Cantidad} onChange={ (e)=>{handleCambiarServ(e.target.value,"Cantidad",index)}}  required />
                                        </div>
                                        <div className="col-2" >
                                            <label htmlFor={`${fieldName}.Total`}>Total*</label>
                                            <input type="text" className="form-control" id={'Total'+index} name={`${fieldName}.Total`} value={object.Subtotal}  readOnly={true} required />
                                        </div>
                                    </div>
                                )
                            })
                            :
                            ""
                        } */}
                                <div className="row" >
                                    <div className="col-sm-7 ">
                                        {/* <h3 className="col-12">Conceptos</h3>
                                {
                                    // trn_productos
                                    servicio.trn_conceptos ?
                                    servicio.trn_conceptos.map((object, index) => {
                                        const fieldName = `catproducto_id[${index}]`;
                                        return (
                                            <div className="form-group form-row col-12" key={index}>
                                                <div className="col-4" >
                                                    <label htmlFor={`${fieldName}.catproducto_id`}>Concepto</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" readOnly={true} value={object.Concepto?object.Concepto:object.cat_producto.Nombre} /> 
                                                    </div>
                                                </div>
                                                <div className="col-2" >
                                                    <label htmlFor={`${fieldName}.Precio`}>Precio</label>
                                                    <input  type="text"  className="form-control" id={'Precio'+index} name={`${fieldName}.Precio`} value={object.Precio} onChange={ (e)=>{handleCambiarServ(e.target.value,"Precio",index)}} required readOnly />
                                                </div>
                                                <div className="col-2" >
                                                    <label htmlFor={`${fieldName}.Cantidad`}>Cant</label>
                                                    <input  type="text" className="form-control" id={'Cantidad'+index}   name={`${fieldName}.Cantidad`} value={object.Cantidad} onChange={ (e)=>{handleCambiarServ(e.target.value,"Cantidad",index)}}  required />
                                                </div>
                                                <div className="col-2" >
                                                    <label htmlFor={`${fieldName}.Total`}>Total</label>
                                                    <input type="text" className="form-control" id={'Total'+index} name={`${fieldName}.Total`} value={object.Subtotal}  readOnly={true} required />
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    ""
                                } */}
                                    </div>
                                    <div className="col-12 col-sm-5  table-responsive" >
                                        <h3 className="col-12">
                                            Detalles de pago
                                        </h3>
                                        <table className="table mb-0 table-striped">
                                            <tr>
                                                <td>Entrega:</td>
                                                <td >
                                                    <DatePicker
                                                        selected={fechaEntrega}
                                                        className="form-control"
                                                        dateFormat="yyyy-MM-dd"
                                                        name="FechaEntrega"
                                                        disabled={false}
                                                        onChange={date => setFechaEntrega(date)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Subtotal:</td>
                                                <td >
                                                    ${
                                                        servicio.Subtotal
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Iva:</td>
                                                <td>
                                                    ${
                                                        servicio.Iva
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total:</td>
                                                <td id="total-cuenta" >
                                                    ${
                                                        servicio.Total
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Anticipo/Pago:</td>
                                                <td >
                                                    ${
                                                        servicio.pago ?
                                                            servicio.pago.reduce((sum, li) => sum + li.TotalPagar, 0)
                                                            :
                                                            "0"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Restante:</td>
                                                <td >
                                                    ${
                                                        servicio.pago ?
                                                            Number(servicio.Total) - Number(servicio.pago.reduce((sum, li) => sum + li.TotalPagar, 0))
                                                            :
                                                            "0"
                                                    }
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-row pt-3"  >
                                    <div className="col-12" >
                                        <h3>Estatus del pedido</h3>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        <label>Estatus</label>
                                        <select className="form-control" name="Estatus" id="Estatus" ref={register({ required: true })} >
                                            <option value="1">Recepción</option>
                                            <option value="2">Analisis</option>
                                            <option value="3B">Reparación - Esperando refacciones</option>
                                            <option value="3A">Reparación - Ejecución</option>
                                            <option value="3C">Reparación - Sitación critica</option>
                                            <option value="4A">Listo para la entrega - Solucionado</option>
                                            <option value="4B">Listo para la entrega - No solucionado</option>
                                            <option value="5A">Se entregó </option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 d-none" id="div-reparado">
                                        <label>Reparado</label>
                                        <select className="form-control " name="Reparado" id="Reparado" ref={register({ required: true })} >
                                            <option value="1">Si</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-3">
                                        {
                                            servicio.Firma
                                                ?
                                                <h3>
                                                    <a href={`${servicio.urlDocs}${servicio.id}/${servicio.Firma}`} target="_black">Ver firma de ingreso a Tecnoland.</a>
                                                </h3>
                                                :
                                                "Sin firma de ingreso a Tecnoland."
                                        }
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3">
                                        {
                                            servicio.FirmaRecibido
                                                ?
                                                <h3>
                                                    <a href={`${servicio.urlDocs}${servicio.id}/${servicio.FirmaRecibido}`} target="_black">Ver firma de entrega al Cliente</a>
                                                </h3>
                                                :
                                                "Sin firma de entrega al Cliente."
                                        }
                                    </div>
                                    {/* <div className="col-12 col-sm-4 col-md-3">
                                <label>Reparado</label>
                                <div className="switch-field">
                                    <input type="radio" id="Reparado" name="Reparado" value="1" />
                                    <label htmlFor="Reparado">SI</label>
                                    <input type="radio" id="Reparado2" name="Reparado" value="0" defaultChecked={true}  />
                                    <label htmlFor="Reparado2" className="no-label" >NO</label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-3">
                                <label>Entregado</label>
                                <div className="switch-field">
                                    <input type="radio" id="Entregado" name="Entregado" value="1"  />
                                    <label htmlFor="Entregado">SI</label>
                                    {
                                    servicio.Pagado !==1 ?
                                    <>
                                    <input type="radio" id="Entregado2" name="Entregado" value="0"  defaultChecked={true}/>
                                    <label htmlFor="Entregado2" className="no-label" >NO</label>
                                    </>
                                    :
                                    ""
                                    }
                                </div>
                            </div> */}
                                </div>
                                {/* <div className="form-row pt-3"  >
                            <div className="col-12" >
                                {
                                    servicio.Firma
                                    ?
                                    <h3>
                                        <a href={`${servicio.urlDocs}${servicio.id}/${servicio.Firma}`} target="_black">Ver Firma</a>
                                    </h3>
                                    :
                                    "Sin firma...."
                                }
                              
                            </div>
                        </div> */}
                                <div className="text-right">
                                    <Link to="/servicios" className="btn btn-secondary">Atras</Link>
                                    <button type="button" onClick={() => { imprimir(servicio.FOLIO) }} className="btn btn-primary ml-2">Imprimir</button>
                                </div>
                            </form>
                            <div id="respuesta" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalTablaCliente titulo="Clientes" urlBusqueda="auth/ventas/clientes-sucursal" elegirElemento={setCliente} idModal="exampleModalCenter" />
            {/* modal segundo abre */}
            <div className="modal fade" id="modal-foto" tabIndex="-1" role="dialog" aria-labelledby={`modal-fotoCenterTitle`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Captura evidenvias</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Webcam
                                audio={false}
                                height={600}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={720}
                                videoConstraints={videoConstraints}
                            />
                        </div>
                        <div className="modal-footer">
                            <button onClick={capture} className="btn btn-primary">Guardar</button>
                            <button type="button" className="btn btn-secondary" id="cerrar-mocal-cliente" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal segundo cierra */}
            {/* modal segundo abre */}
            <div className="modal fade" id="modal-qr" tabIndex="-1" role="dialog" aria-labelledby={`modal-fotoqr`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Datos de QR</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <Webcam
                        audio={false}
                        height={600}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={720}
                        videoConstraints={videoConstraints}
                    /> */}
                            <div className="row" >
                                <div className="col-sm-6 col-12" >
                                    <h3>Seguimiento</h3>
                                    <div className="text-center" style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: servicio.qr }} />
                                </div>
                                <div className="col-sm-6 col-12" >
                                    <h3>Firma y patrón</h3>
                                    <div className="text-center" style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: servicio.qrRep }} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="cerrar-mocal-cliente" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal segundo cierra */}
            {/* <ModalTablaProductoSinInventario titulo="Servicios" urlBusqueda="auth/inventario/inventario-usuario-servicios" elegirElemento={onChangleSeleccionarServicio} idModal="ModalServicios" />
    <ModalTablaProducto titulo="Productos" urlBusqueda="auth/inventario/inventario-usuario" elegirElemento={onChangleSeleccionarProducto} idModal="ModalProducto" />
    <ModalTablaProductoSinInventario titulo="Productos/Conceptos sin inventario" urlBusqueda="auth/inventario/inventario-usuario-sin-inventario" elegirElemento={escogerConcepto} idModal="ModalProductoSinInventario" /> */}
        </>

    )
}

export default VerServicio;