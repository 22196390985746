import React,{useState, useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import {    Link  } from "react-router-dom";

const VerAlmacen =() => {
    const [sucursales,setSucursales] = useState({})
    const URLCATEGORIALEER = 'auth/inventarios/almacenes-mostrar/'
    let { id } = useParams();
    const almacen  =obtenerData(URLCATEGORIALEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    const traerSucursales =async () => {
        const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
        if(sucursales || sucursales.length===0){
            sucursales.unshift({value: "", label: '--Selecciona un valor--'})
            setSucursales(sucursales);
        }else{
            setSucursales({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    useEffect(
        ()=>{
        const asignar =(almacen) =>{
            traerSucursales()
            setValue('Nombre', almacen.Nombre)
            setValue('Direccion', almacen.Direccion)
            setValue('Descripcion', almacen.Descripcion)
            }
        asignar(almacen)
        }
    ,[setValue,almacen]);
    
    return (
    <>
    <Titulo titulo="Ver almacen" tituloBajo="Almacenes &gt; Ver almacen"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Almacen</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Dirección*</label>
                            <input type="text" className="form-control" id="Direccion" name="Direccion" ref={register({ required: true })}  />
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Sucursal*</label>
                            <select className="form-control" name="catsucursal_id" id="catsucursal_id" ref={register({ required: true })} disabled="true" >
                                {
                                    sucursales.length >0 ?
                                    sucursales.map((object, index) => (
                                        almacen.id ?
                                        almacen.catsucursal_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        

                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/almacenes" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerAlmacen;