import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

export default ({ buscarData, icono, ruta, permiso, fechas, title = '', btns }) => {
    const [buscar, setBuscar] = useState({ palabras: '', fechaInicial: new Date(), fechaFinal: new Date() });
    // const [fechaInicial, setFechaInicial] = useState(new Date());
    // const [fechaFinal, setFechaFinal] = useState(new Date());
    // useCallback//https://es.reactjs.org/docs/hooks-reference.html#callback

    permiso = permiso.Crear === 1 ? true : false;
    const buscarDataPalabra = useCallback(
        () => {
            buscarData(buscar.palabras, buscar.fechaInicial ?? '', buscar.fechaFinal ?? '');
        },
        [buscar, buscarData],
    );
    const setFechaInicial = (fecha) => {
        const objBusqueda = buscar;
        objBusqueda.fechaInicial = fecha;
        setBuscar({ ...buscar, objBusqueda })
    }
    const setFechaFinal = (fecha) => {
        const objBusqueda = buscar;
        objBusqueda.fechaFinal = fecha;
        setBuscar({ ...buscar, objBusqueda })
    }
    const setPalabras = (e) => {
        const objBusqueda = buscar;
        objBusqueda.palabras = e.target.value;
        setBuscar({ ...buscar, objBusqueda })
    }
    const busacarEnter = (e) => {
        var keycode = e.keyCode || e.which;
        if (keycode == 13) buscarDataPalabra();
    }
    return (
        <>
            {
                fechas ?
                    <div className="form-row">
                        <div className="col-4">
                            <label htmlFor="fechaI">Fecha inicial</label>
                            <DatePicker
                                selected={buscar.fechaInicial}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                name="FechaInicial"
                                id="fechaI"
                                onChange={date => setFechaInicial(date)}
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="fechaF">Fecha final</label>
                            <DatePicker
                                selected={buscar.fechaFinal}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                name="FechaFinal"
                                id="fechaF"
                                onChange={date => setFechaFinal(date)}
                            />
                        </div>
                        <div className="col-1">
                            <label htmlFor="fechaF"></label>
                            <button type="submit" id="enviar" style={{ marginBottom: "-17px" }} onClick={buscarDataPalabra} className="btn btn-primary">Filtrar</button>
                        </div>
                    </div>
                    :
                    ""
            }

            <div className="input-group mb-1 col-md-5 abosule-right">
                <input type="text" className="form-control" id="input" style={{ padding: "0.45rem 1.07rem" }} onKeyUp={e => busacarEnter(e)} onChange={e => setPalabras(e)} placeholder="Buscar..." aria-label="Buscar..." aria-describedby="button-addon2" />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={buscarDataPalabra} >Buscar</button>
                </div>
                {
                    permiso ?
                        <Link to={ruta}>
                            <img src={icono} style={{ height: "32px" }} alt="settings" className="pl-3" title={(title ?? '')}></img>
                        </Link>
                        :
                        ""

                }
                {

                    btns && btns[0] ?
                        btns[0].permiso ?
                            <Link to={btns[0].ruta}>
                                <img src={btns[0].icono} style={{ height: "32px" }} alt="settings" className="pl-3" title={btns[0].title}></img>
                            </Link>
                            :
                            ""
                        :
                        ""

                }
                {

                    btns && btns[1] ?
                        btns[1].permiso ?
                            <Link to={btns[1].ruta}>
                                <img src={btns[1].icono} style={{ height: "32px" }} alt="settings" className="pl-3" title={btns[1].title}></img>
                            </Link>
                            :
                            ""
                        :
                        ""

                }
            </div>
        </>
    )
}

