import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm, Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link,useParams  } from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'


const VerMovimiento =() => {
    const URLAJUSTESLEER = 'auth/inventarios/trnmovimientos-mostrar/'
    let { id } = useParams();
    const ajuste  =obtenerData(URLAJUSTESLEER,id,1)
    const { register, handleSubmit, errors, setValue, control  } = useForm()

 
    return (
    <>
    <Titulo titulo="Ver movimiento" tituloBajo="Movimientos &gt; Ver movimiento"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Ajustes inventario</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="catproducto_id">Producto*</label>
                            <label htmlFor="catproducto_id" className="form-control">{ajuste.catproducto ? ajuste.catproducto.Nombre : "N/A"}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cattipomovimiento_id">Tipo movimiento*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.cattipomovimiento ? ajuste.cattipomovimiento.Nombre : "N/A"}</label>

                        </div>
                        <div className="form-group">
                            <label htmlFor="catalmacen_id">Almacen*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.cat_almacen ? ajuste.cat_almacen.Nombre : "N/A"}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Cantidad">Cantidad*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.Cantidad }</label>
                        </div>
                        <div id="respuesta" ></div>
                        <div  className="text-right">
                            <Link to="/movimientoalmacenes" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerMovimiento;