import React, {useEffect} from 'react';
import Titulo from '../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const AgregarRoles =() => {
    const URLROLLEER = 'auth/admin/roles-edit'
    let { id } = useParams();
    const rol  =obtenerData(URLROLLEER,id)
    const { register, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(rol) =>{
            setValue('Nombre', rol.Nombre)
            setValue('Descripcion', rol.Descripcion)
            }
        asignar(rol)
        }
    ,[setValue,rol]);


    return (
    <>
    <Titulo titulo="Ver rol" tituloBajo="Roles &gt; ver rol"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Rol</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="rol">Rol*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/roles" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarRoles;