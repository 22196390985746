import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const VerProductosFactura =() => {
    const URLPRODUCTOFACTLEER = 'auth/inventarios/productosfacturas-mostrar/'
    let { id } = useParams();
    const productoFact  =obtenerData(URLPRODUCTOFACTLEER,id,1)
    const { register,  errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(productoFact) =>{
            setValue('Codigo', productoFact.Codigo)
            setValue('Alias', productoFact.Alias)
            setValue('Descripcion', productoFact.Descripcion)
            }
        asignar(productoFact)
        }
    ,[setValue,productoFact]);
    

    return (
    <>
    <Titulo titulo="Ver clave SAT" tituloBajo="Claves SAT &gt; ver claves SAT"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Producto SAT</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="Codigo">Código*</label>
                            <input type="text" className="form-control" id="Codigo" name="Codigo" ref={register({ required: true, maxLength: 60 })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y su longitud no puede ser mayor a 60!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Alias">Alias*</label>
                            <input type="text" className="form-control" id="Alias" name="Alias" ref={register({ required: true, maxLength: 50 })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y su longitud no puede ser mayor a 50!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/productosfact" className="btn btn-secondary">Atras</Link>
                            {/* <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerProductosFactura;