import React, { useState } from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import { useParams } from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import { Link } from "react-router-dom";

const ConsultarVenta = () => {
    const URLVENTALEER = 'auth/ventas/ventas-mostrar/'
    let { id } = useParams();
    const venta = obtenerData(URLVENTALEER, id, 1)
    const URLVENTASTICKET = 'auth/ventas/ticket/'
    const [montopago, setMontoPago] = useState(0);
    const [montopagodec, setMontoPagodec] = useState(0);
    const [visiblemontopago, setVisiblemontopago] = useState(true);
    const { register, handleSubmit, errors, setValue } = useForm()
    const [ventaPago, setVentaPago] = useState({ id: null, monto: 0 })
    const caja = obtenerData('auth/cierreDeCaja/Estatus', '', 1);

    const validarCampoDecPago = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            setMontoPagodec(valor)
        }
    }
    const validarCampoEnteroPag = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            setMontoPago(valor)
        }
    }

    /* JMMC SISDESA */
    const mostrarMensajeAbrirCaja = (fecha) => {
        document.getElementById('btnPagar').classList.add('oculto');
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA CERRADA",
            text: `¿Quieres abrir la caja?`,
            icon: "warning",
            // button: "Aceptar"
            buttons: ["No abrir caja", "Si abrir caja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                // swal("CAJA ABIERTA", "La caja ha sido abierta correctamente.", "success");
                history.push('/AbrirCaja');
            }
        });
    }

    const mostrarMensajeCerrarCaja = (fecha, fechaBD) => {
        document.getElementById('btnPagar').classList.add('oculto');
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA ABIERTA",
            text: `La caja ha sido abierta el ${funciones.getFechaTexto(fechaBD)}, necesitas cerrarla para poder abrirla hoy.`,
            icon: "warning",
            // button: "Aceptar"
            buttons: ["No cerrar caja", "Si cerrar caaja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                // swal("CAJA ABIERTA", "La caja ha sido abierta correctamente.", "success");
                history.push('/CerrarCaja');
            }
        });
    }

    const verificarCaja = async () => {
        try {
            if (venta.Pagado) {
                return 0;
            }

            let dateHoy = new Date();
            const fechaHoy = funciones.getFecha(dateHoy);
            /*---*/
            if (caja[0].Estatus != 'Abierta') {
                mostrarMensajeAbrirCaja(fechaHoy);
                return 0;
            }
            /*---*/
            let dateBD = new Date(caja[0].FechaAperturaCC);
            const fechaBD = funciones.getFecha(dateBD);
            if (caja[0].Estatus == 'Abierta' && fechaBD != fechaHoy) {
                mostrarMensajeCerrarCaja(fechaHoy, dateBD);
            }

        } catch (error) {
            console.error(error);
        }
    }

    verificarCaja();
    /* JMMC SISDESA */

    const pagar = async (data, e) => {
        try {
            let montoRecibo = Number(`${montopago}.${montopagodec}`);
            if (montoRecibo < venta.Total) {
                return swal({
                    title: "MONTO INSUFICIENTE",
                    text: `El "Monto recibido" no puede ser menor al "Monto a pagar".`,
                    icon: "warning",
                    button: "Aceptar",
                });
            }
            let formData = new FormData(document.getElementById("formPagar"));
            formData.append("idVenta", venta.id)
            const response = await API.post('auth/ventas/crear-pago', formData);
            if (response.status === 201) {
                swal({
                    title: "Registrado",
                    text: "El pago se llevo correctamente",
                    icon: "success",
                    button: "Aceptar"
                });
                history.push('/ventas')
            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));

            }
        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
            if (document.getElementById("enviar"))
                document.getElementById("enviar").disabled = false;


        }
    }
    var pago = "";
    if (venta.pago) {
        switch (venta.pago.Pagocon) {
            case "TD":
                pago = "Tarjeta de débito";
                break;
            case "TC":
                pago = "Tarjeta de crédito";
                break;
            case "TC":
                pago = "Tarjeta de crédito";
                break;
            case "E":
                pago = "Efectivo";
                break;
            case "NA":
                pago = "NA";
                break;
        }

    }
    const imprimir = async (id) => {
        try {
            const response = await API.get(URLVENTASTICKET + id);
            if (response.status === 200) {
                var venta = response.data;
                var contenidoHtml = "<div style='width:300px' >";
                contenidoHtml += "<h1>" + venta.cat_sucursal.Nombre + "</h1>";
                contenidoHtml += "<p>" + venta.cat_sucursal.Direccion + "</p>";
                contenidoHtml += "<p>CEL: " + venta.cat_sucursal.Telefono + "</p>";
                contenidoHtml += "<p> " + venta.facebook + "</p>";
                contenidoHtml += "<p> " + venta.created_at + "</p>";
                contenidoHtml += "<table>";
                contenidoHtml += "<tr>";
                contenidoHtml += "<td>Folio</td>";
                contenidoHtml += "<td>" + venta.id + "</td>";
                contenidoHtml += "</tr>";
                contenidoHtml += "</table>";
                contenidoHtml += "<table>";
                contenidoHtml += "<thead>";
                contenidoHtml += "<tr>";
                contenidoHtml += "<th>Cant.</th>";
                contenidoHtml += "<th>Decipcion</th>";
                contenidoHtml += "<th>Importe</th>";
                contenidoHtml += "</tr>";
                venta.trn_detalles_ventas.forEach(element => {
                    contenidoHtml += "<tr>";
                    contenidoHtml += "<td>" + element.Cantidad + "</td>";
                    contenidoHtml += "<td>" + element.cat_producto.Nombre + "</td>";
                    contenidoHtml += "<td>" + element.Subtotal + "</td>";
                    contenidoHtml += "</tr>";
                });
                contenidoHtml += "<tr>";
                contenidoHtml += "<td></td>";
                contenidoHtml += "<td>IVA</td>";
                contenidoHtml += "<td>" + venta.IVA + "</td>";
                contenidoHtml += "</tr>";
                contenidoHtml += "<tr>";
                contenidoHtml += "<td></td>";
                contenidoHtml += "<td>Total</td>";
                contenidoHtml += "<td>" + venta.Total + "</td>";
                contenidoHtml += "</tr>";
                //  
                contenidoHtml += "</thead>";
                contenidoHtml += "</table>";
                contenidoHtml += "<p>" + venta.texto + "</p>";
                contenidoHtml += "</div>";

                var ticket = document.createElement("div");
                ticket.innerHTML = contenidoHtml;

                var ventimp = window.open(' ', 'popimpr');
                ventimp.document.write(ticket.innerHTML);
                ventimp.document.close();
                ventimp.print();
                ventimp.close();
                // swal("Registro elimnado correctamente", {
                //     icon: "success",
                // });
                // cambiarPagina(paginas.filter(page=>page.active)[0].label);
            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));
            }

        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
        }


    }
    const changeEfectivo = (e) => {
        if (e.target.value === "E") {
            setVisiblemontopago(true)
            setMontoPago(0)
        } else {
            setVisiblemontopago(false)
            setMontoPagodec(0)
        }


    }
    return (
        <>
            <Titulo titulo="Ver venta" tituloBajo="Ventas &gt; Ver venta"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="row" >

                            {
                                venta.id ?
                                    <div className="widget-header bordered no-actions d-flex align-items-center justify-content-between">
                                        {
                                            // ventaPago.id === null ?
                                            //     <h4>Datos de venta</h4>
                                            //     :
                                            //     <h4>Información de pago</h4>
                                        }
                                        <h4 style={{ color: "#2c304d" }}>#{venta.id}</h4>

                                    </div>
                                    :
                                    ""
                            }
                            {/* <div className=" bordered no-actions d-flex align-items-center text-right">
                    {
                        venta.id?
                        <h4>#{venta.id}</h4>
                        :
                        "..."
                    }
                    </div> */}
                        </div>
                        <div className="widget-body" >
                            {
                                ventaPago.id === null ?
                                    <form >
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-right">
                                                    {
                                                        venta.pago == null
                                                            ?
                                                            <button type="button" className="btn btn-danger" id="btnPagar" onClick={(e) => { setVentaPago({ id: venta.id, monto: venta.Total }) }}>Pagar</button>
                                                            :
                                                            <label type="button" className="btn btn-default" >{pago}</label>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row" >
                                            <div className="col-6" >
                                                <label htmlFor="catsucursal_id">Cliente*</label>
                                                <label type="text" className="form-control"   >
                                                    {
                                                        venta.id
                                                            ?
                                                            venta.cat_cliente.Nombre
                                                            :
                                                            ""
                                                    }
                                                </label>
                                            </div>
                                            <div className="col-6" >
                                                <div className="form-group">
                                                    <label htmlFor="catsucursal_id">Colaborador*</label>
                                                    <label type="text" className="form-control"   >
                                                        {
                                                            venta.cat_empleado
                                                                ?
                                                                venta.cat_empleado.Nombre
                                                                :
                                                                "Sin empleado"
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {
                                            venta.trn_detalles_ventas ?
                                                venta.trn_detalles_ventas.map((object, index) => {
                                                    const fieldName = `catproducto_id[${index}]`;
                                                    return (
                                                        <div className="form-row" key={index} >
                                                            <div className="col-4" >
                                                                <label htmlFor={`${fieldName}.Nombre`}>Producto.</label>
                                                                <label className="form-control"   >
                                                                    {
                                                                        object.cat_producto
                                                                            ?
                                                                            object.cat_producto.Nombre
                                                                            :
                                                                            ""
                                                                    }
                                                                </label>
                                                            </div>
                                                            <div className="col-2" >
                                                                <label htmlFor={`${fieldName}.Precio`}>Precio.</label>
                                                                <label className="form-control">${object.Precio}</label>
                                                            </div>
                                                            <div className="col-2" >
                                                                <label htmlFor={`${fieldName}.Cantidad`}>Cant.</label>
                                                                <label className="form-control">{object.Cantidad}</label>
                                                            </div>
                                                            <div className="col-2" >
                                                                <label htmlFor={`${fieldName}.Total`}>Total*</label>
                                                                <label className="form-control">${object.Subtotal}</label>
                                                            </div>
                                                        </div>

                                                    )

                                                })
                                                :
                                                "Cargando..."
                                        }
                                        <hr />
                                        <div className="form-row" >
                                            <div className="form-group col-12">
                                                <h3>Conceptos</h3>
                                                {
                                                    // trn_productos
                                                    venta.trn_conceptos ?
                                                        venta.trn_conceptos.map((object, index) => {
                                                            const fieldName = `catproducto_id[${index}]`;
                                                            return (
                                                                <div className="form-row" key={index}>
                                                                    <div className="col-4" >
                                                                        <label htmlFor={`${fieldName}.catproducto_id`}>Concepto</label>
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control" readOnly={true} value={object.Concepto ? object.Concepto : object.cat_producto.Nombre} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2" >
                                                                        <label htmlFor={`${fieldName}.Precio`}>Precio</label>
                                                                        <input type="text" className="form-control" id={'Precio' + index} name={`${fieldName}.Precio`} value={'$' + object.Precio} required readOnly />
                                                                    </div>
                                                                    <div className="col-2" >
                                                                        <label htmlFor={`${fieldName}.Cantidad`}>Cant</label>
                                                                        <input type="text" className="form-control" id={'Cantidad' + index} name={`${fieldName}.Cantidad`} value={object.Cantidad} required />
                                                                    </div>
                                                                    <div className="col-2" >
                                                                        <label htmlFor={`${fieldName}.Total`}>Total</label>
                                                                        <input type="text" className="form-control" id={'Total' + index} name={`${fieldName}.Total`} value={'$' + object.Subtotal} readOnly={true} required />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        ""
                                                }
                                            </div>
                                            <div className="form-group col-8">
                                            </div>
                                            <div className="col-4" >
                                                <table>
                                                    <tr>
                                                        <td>Subtotal:</td>
                                                        <td >
                                                            {
                                                                '$' + venta.Subtotal
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Iva</td>
                                                        <td >
                                                            {

                                                                '$' + venta.IVA

                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td >
                                                            {
                                                                '$' + venta.Total
                                                            }
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <Link to="/ventas" className="btn btn-secondary">Atras</Link>
                                            {
                                                venta.Pagado === 1 ?
                                                    <button className="btn btn-info ml-3" onClick={() => imprimir(id)} type="button"
                                                    >Imprimir</button>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </form>
                                    :
                                    <form id="formPagar" onSubmit={handleSubmit(pagar)} >
                                        <div className="form-row">
                                            <div className="col-4" >
                                            </div>
                                            <div className="col-4">
                                                <label>Forma de pago</label>
                                                <select className="form-control " name="Pagocon" id="Pagocon" onChange={(e) => { changeEfectivo(e) }}   >
                                                    <option value="E">Efectivo</option>
                                                    <option value="TD">Tarjeta de débito</option>
                                                    <option value="TC">Tarjeta de crédito</option>
                                                    <option value="NA">NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-4" >
                                            </div>
                                            <div className="col-4">
                                                <label>Monto a pagar</label>
                                                <label className="form-control"  >{ventaPago.monto}</label>
                                            </div>
                                        </div>
                                        {
                                            visiblemontopago ?
                                                <>
                                                    <div className="form-row">
                                                        <div className="col-4" >
                                                        </div>
                                                        <div className="col-4">
                                                            <label>Monto recibido</label>
                                                            <div className="row pl-3" >
                                                                <input type="text" className="form-control col-4" onChange={(e) => { validarCampoEnteroPag(e.target.value) }} value={montopago} />
                                                                <label style={{ color: "black", fontSize: "24px" }} className="pl-1 pr-1">.</label>
                                                                <input type="text" className="form-control col-2" onChange={(e) => { validarCampoDecPago(e.target.value) }} value={montopagodec} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-4" >
                                                        </div>
                                                        <div className="col-4">
                                                            <label>Monto a devolver</label>
                                                            <label className="form-control"  >{(Number(Number(montopago) + "." + montopagodec) - ventaPago.monto).toFixed(2) <= 0 ? 0 : (Number(Number(montopago) + "." + montopagodec) - ventaPago.monto).toFixed(2)}</label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                ""
                                        }
                                        <div className="form-row">
                                            <div className="col-4" >
                                            </div>
                                            <div className="col-4">
                                                <button type="Submit" className="btn btn-primary mr-3" >Pagar</button>
                                                <button onClick={(e) => { setVentaPago({ id: null, monto: null }) }} className="btn btn-secondary">Regresar a las ventas</button>
                                            </div>
                                        </div>
                                    </form>
                            }
                            <div id="respuesta" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ConsultarVenta;