
const funciones = {
    getFormData: (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
            formData.append(key, object[key])
        });
        return formData;
    },
    mostrarElemento: (elemento) => {
        var elementoMostrar = document.getElementById(elemento)
        if (elementoMostrar)
            elementoMostrar.classList.remove("d-none")
        return true;
    },
    ocultarElemento: (elemento) => {
        var elementoMostrar = document.getElementById(elemento)
        if (elementoMostrar)
            elementoMostrar.classList.add("d-none")
        return true;
    },
    resetearFormulario: (elemento) => {
        document.getElementById(elemento).reset()
        return true;
    },
    tratarerrores: (error, elemento) => {
        try {
            var id;
            var incremento = 0;
            if (!error.response) {
                elemento.innerHTML = "Un error con el servidor 500";
                id = setInterval(frame, 100);
                return;
            }
            var status = error.response.status;
            var data = error.response.data;

            switch (status) {
                case 401:
                    elemento.innerHTML = data.message;
                    if (data.message === "Unauthenticated." || data.errors === "Unauthenticated") {
                        window.location.replace('');
                        localStorage.clear();
                    }
                    id = setInterval(frame, 100);

                    break;
                case 422:
                    var errores = data.errors;
                    var erroresTexto = "";
                    // console.log(errores)
                    errores.forEach((element, num, index) => {
                        erroresTexto = erroresTexto + element + "<br>";
                    });
                    elemento.innerHTML = erroresTexto;
                    id = setInterval(frame, 100);
                    break;
                case 500:
                    var mensaje = "";
                    if (data.message) {
                        mensaje = data.message
                    } else {
                        mensaje = data.errors
                    }
                    elemento.innerHTML = error.response.statusText + " 500: " + mensaje;
                    id = setInterval(frame, 100);
                    break;
                case 404:
                    var mensaje = "";
                    if (data.message) {
                        mensaje = data.message
                    } else {
                        mensaje = data.errors
                    }
                    elemento.innerHTML = error.response.statusText + " 404: " + mensaje;
                    id = setInterval(frame, 100);
                    break;
                default:
                    break;
            }
            function frame() {
                if (incremento === 4) {
                    clearInterval(id);
                } else {
                    incremento += 1;
                    elemento.classList.toggle("text-red");
                }
            }
            // document.getElementById(elemento).reset()
            return true;
        } catch (error) {
            console.log(error)
        }

    },
    b64toBlob: (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },
    escogerValorRadio: (name, valor) => {
        document.querySelectorAll('input[name="' + name + '"]').forEach(element => {
            if (element.value == valor) {
                element.checked = true;
                return;
            }
        })
    },
    imprimirVenta: (venta, logo) => {
        var contenidoHtml = "<div style='width:100%;  text-align: center;font-family: monospace;' >";
        contenidoHtml += "<img src='" + logo + "' style='width:83%' />";
        contenidoHtml += "<h4 style='margin-top: 3px;margin-bottom: 0px;background-color:black;  color: white;-webkit-print-color-adjust: exact;printer-colors: exact;color-adjust: exact;' >Atencion clientes: " + venta.cat_sucursal.Telefono + "</h4>";
        contenidoHtml += "<p style='margin-top: 1px;margin-bottom: 1px;'>" + venta.cat_sucursal.Nombre + ", " + venta.cat_sucursal.Direccion + "</p>";
        contenidoHtml += "<p style='margin-top: 1px;margin-bottom: 1px;'> " + venta.created_at + "</p>";
        contenidoHtml += "<table style='margin-top: 1px;'>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td>Folio</td>";
        contenidoHtml += "<td>" + venta.id + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "</table>";
        contenidoHtml += "<table style='border-collapse: collapse;width: 100%;'>";
        contenidoHtml += "<thead>";
        contenidoHtml += "<tr style=' border: 1px solid black;witdh'>";
        contenidoHtml += "<th>Cant.</th>";
        contenidoHtml += "<th>Desc.</th>";
        contenidoHtml += "<th>Prec.</th>";
        contenidoHtml += "<th>Tot.</th>";
        contenidoHtml += "</tr>";
        contenidoHtml += "</thead>";
        contenidoHtml += "<tbody  style='font-size: 10px;'>";
        venta.trn_detalles_ventas.forEach(element => {
            contenidoHtml += "<tr>";
            contenidoHtml += "<td>" + element.Cantidad + "</td>";
            contenidoHtml += "<td>" + element.cat_producto.Nombre + "</td>";
            contenidoHtml += "<td>" + element.Precio + "</td>";
            contenidoHtml += "<td style='text-align: center;'>" + element.Subtotal + "</td>";
            contenidoHtml += "</tr>";
        });
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>IVA</td>";
        contenidoHtml += "<td style='text-align: center;'>" + venta.IVA + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>Subtotal</td>";
        contenidoHtml += "<td style='text-align: center;'>" + venta.Subtotal + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>Total</td>";
        contenidoHtml += "<td style='text-align: center;'>" + venta.Total + "</td>";
        contenidoHtml += "</tr>";
        //  
        contenidoHtml += "</tbody>";
        contenidoHtml += "</table>";
        contenidoHtml += "<p style='margin-bottom:0px; font-size: 8px;'>" + venta.texto + "</p>";
        contenidoHtml += "<div>" + venta.qr + " <p style='margin-top: -11px;font-size: 12px;'>https://tecnoland.com.mx</p></div>";

        contenidoHtml += "</div>";

        var ticket = document.createElement("div");
        ticket.innerHTML = contenidoHtml;

        var ventimp = window.open(' ', 'popimpr');
        ventimp.document.write(ticket.innerHTML);
        ventimp.document.close();
        ventimp.document.querySelector("body").style.margin = "0px";
        ventimp.print();
        ventimp.close();
    },
    imprimirServicio: (servicio, logo) => {
        var pagado = servicio.pago ? Number(servicio.pago.reduce((sum, li) => sum + li.TotalPagar, 0)) : 0


        var contenidoHtml = "<div style='width:100%;  text-align: center;font-family: monospace;' >";
        contenidoHtml += "<img src='" + logo + "' style='width:83%' />";
        contenidoHtml += "<h4 style='margin-top: 3px;margin-bottom: 0px;background-color:black;  color: white;-webkit-print-color-adjust: exact;printer-colors: exact;color-adjust: exact;' >Atencion clientes: " + servicio.cat_sucursal.Telefono + "</h4>";
        contenidoHtml += "<p style='margin-top: 1px;margin-bottom: 1px;'>" + servicio.cat_sucursal.Nombre + ", " + servicio.cat_sucursal.Direccion + "</p>";
        contenidoHtml += "<p style='margin-top: 1px;margin-bottom: 1px;'> " + servicio.created_at + "</p>";
        contenidoHtml += "<p style='margin-top: 1px;margin-bottom: 1px;'> " + servicio.horarioSucursal + "</p>";
        contenidoHtml += "<table>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td>Folio</td>";
        contenidoHtml += "<td>" + servicio.FOLIO + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "</table>";
        contenidoHtml += "<table style='border-collapse: collapse;width: 100%;'>";
        contenidoHtml += "<thead>";
        contenidoHtml += "<tr style=' border: 1px solid black;'>";
        contenidoHtml += "<th>Cant.</th>";
        contenidoHtml += "<th>Desc.</th>";
        contenidoHtml += "<th>Prec.</th>";
        contenidoHtml += "<th>Tot.</th>";
        contenidoHtml += "</tr>";
        contenidoHtml += "</thead>";
        contenidoHtml += "<tbody style='font-size: 10px;'>";
        servicio.trn_serviciosdetalles.forEach(element => {
            contenidoHtml += "<tr>";
            contenidoHtml += "<td>" + element.Cantidad + "</td>";
            contenidoHtml += "<td>" + element.cat_producto.Nombre + "</td>";
            contenidoHtml += "<td>" + element.Subtotal + "</td>";
            contenidoHtml += "<td  style='text-align: center;'>" + element.Subtotal + "</td>";
            contenidoHtml += "</tr>";
        });

        servicio.trn_productos.forEach(element => {
            contenidoHtml += "<tr>";
            contenidoHtml += "<td>" + element.Cantidad + "</td>";
            contenidoHtml += "<td>" + element.cat_producto.Nombre + "</td>";
            contenidoHtml += "<td>" + element.Precio + "</td>";
            contenidoHtml += "<td  style='text-align: center;'>" + element.Subtotal + "</td>";
            contenidoHtml += "</tr>";
        });
        servicio.trn_conceptos.forEach(element => {
            contenidoHtml += "<tr>";
            contenidoHtml += "<td>" + element.Cantidad + "</td>";
            if (element.Concepto) {
                contenidoHtml += "<td>" + element.Concepto + "</td>";
            } else {
                contenidoHtml += "<td>" + element.cat_producto.Nombre + "</td>";
            }
            contenidoHtml += "<td>" + element.Precio + "</td>";
            contenidoHtml += "<td  style='text-align: center;'>" + element.Subtotal + "</td>";
            contenidoHtml += "</tr>";
        });
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>IVA</td>";
        contenidoHtml += "<td style='text-align: center;'>" + servicio.Iva + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>Subtotal</td>";
        contenidoHtml += "<td style='text-align: center;'>" + servicio.Total + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>Pagado</td>";
        contenidoHtml += "<td style='text-align: center;'>" + pagado + "</td>";
        contenidoHtml += "</tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<tr>";
        contenidoHtml += "<td colspan='3' style='text-align: right;'>Restante a pagar</td>";
        contenidoHtml += "<td style='text-align: center;'>" + (servicio.Total - pagado) + "</td>";
        contenidoHtml += "</tr>";
        //  
        contenidoHtml += "</tbody>";
        contenidoHtml += "</table>";
        contenidoHtml += "<p  style='margin-bottom:0px; font-size: 8px;'>" + servicio.texto + "</p>";
        contenidoHtml += "<div>" + servicio.qr + "<p style='margin-top: -11px;font-size: 10px;'>Consulte el estado de la reparación en nuestra página " + servicio.paginaPrincipal + "  con el folio: " + servicio.FOLIO + "</p></div>";
        contenidoHtml += "</div>";

        var ticket = document.createElement("div");
        ticket.innerHTML = contenidoHtml;
        var ventimp = window.open(' ', 'popimpr');
        ventimp.document.write(ticket.innerHTML);
        ventimp.document.querySelector("body").style.margin = "0px";
        ventimp.document.close();
        ventimp.print();
        ventimp.close();
    },
    camara: (element) => {
        var op = 1;  // initial opacity
        var timer = setInterval(() => {
            if (op <= 0.1) {
                clearInterval(timer);
                var timerO = setInterval(function () {
                    if (op >= 1) {
                        clearInterval(timerO);
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.1;
                }, 10);
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.1;
        }, 10);
    },
    fade: (element) => {
        var op = 1;  // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.1) {
                clearInterval(timer);
                element.style.display = 'none';
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.1;
        }, 50);
    },
    unfade: (element) => {
        var op = 0.1;  // initial opacity
        element.style.display = 'block';
        var timer = setInterval(function () {
            if (op >= 1) {
                clearInterval(timer);
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.1;
        }, 10);
    },
    getFecha(fecha) {
        try {
            return `${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${(fecha.getDate()).toString().padStart(2, '0')}`;
        } catch (error) {
            fecha = new Date();
            return `${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${(fecha.getDate()).toString().padStart(2, '0')}`;
        }
    },
    getFechaTexto(fecha) {
        // fecha = substr(fecha, 0, 10);
        let dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
        let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        try {
            fecha = new Date(fecha);
        } catch (error) {
            fecha = new Date();
        }
        let numeroDia = (fecha.getDate()).toString().padStart(2, '0');
        let dia = dias[fecha.getDay()];
        let mes = meses[fecha.getMonth()];
        let anio = fecha.getFullYear();
        return `${dia} ${numeroDia} de ${mes} de ${anio}`;
    }

}
export default funciones;