import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";

const EditarUnidadesClave =() => {
    const URLUNIDADESCVLLEER = 'auth/inventarios/unidades-mostrar/'
    let { id } = useParams();
    const unidadescvl  =obtenerData(URLUNIDADESCVLLEER,id,1)
    const { register,  errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(unidadescvl) =>{
            setValue('Clave', unidadescvl.Clave)
            setValue('Nombre', unidadescvl.Nombre)
            setValue('Simbolo', unidadescvl.Simbolo)
            }
        asignar(unidadescvl)
        }
    ,[setValue,unidadescvl]);
    
  
    return (
    <>
    <Titulo titulo="Producto SAT" tituloBajo="Producto SAT &gt; Agregar registro"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Producto SAT</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="Clave">Clave*</label>
                            <input type="text" className="form-control" id="Clave" name="Clave" ref={register({ required: true })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío !
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío !
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Simbolo">Simbolo*</label>
                            <input type="text" className="form-control" id="Simbolo" name="Simbolo" ref={register({ required: true })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/unidadescvl" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarUnidadesClave;