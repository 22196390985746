import React, { useState } from 'react';
// import React from 'react';
import { Link } from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png';
import iconoPrint from '../../img/icono-print.png';
import swal from 'sweetalert'
import logo from './../../../logoimp.png';
import "react-datepicker/dist/react-datepicker.css";
import obtenerData from '../../../librerias/obtenerData'
import history from '../../../librerias/history';

const Ventas = () => {
    const URLVENTAS = 'auth/ventas/ventas'
    const URLVENTASELIMINAR = 'auth/ventas/ventas-eliminar/'
    const URLVENTASTICKET = 'auth/ventas/ticket/'
    const { cargando, data: ventas, paginas, cambiarPagina, buscarData, current } = obtenerDatos(URLVENTAS);
    const permisos = obtenerPermisos("modulo-ventas");
    const caja = obtenerData('auth/cierreDeCaja/Estatus', '', 1);

    /* JMMC SISDESA */
    const mostrarMensajeAbrirCaja = (fecha) => {
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA CERRADA",
            text: `¿Quieres abrir la caja?`,
            icon: "warning",
            buttons: ["No abrir caja", "Si abrir caja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                history.push('/AbrirCaja');
            }
        });
    }

    const mostrarMensajeCerrarCaja = (fecha, fechaBD) => {
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA ABIERTA",
            text: `La caja ha sido abierta el ${funciones.getFechaTexto(fechaBD)}, necesitas cerrarla para poder abrirla hoy.`,
            icon: "warning",
            buttons: ["No cerrar caja", "Si cerrar caja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                history.push('/CerrarCaja');
            }
        });
    }
    /* JMMC SISDESA */

    const eliminar = async (id) => {

        /* JMMC SISDESA */
        try {
            let dateHoy = new Date();
            const fechaHoy = funciones.getFecha(dateHoy);
            /*---*/
            if (caja[0].Estatus != 'Abierta') {
                mostrarMensajeAbrirCaja(fechaHoy);
                return 0;
            }
            /*---*/
            let dateBD = new Date(caja[0].FechaAperturaCC);
            const fechaBD = funciones.getFecha(dateBD);
            if (caja[0].Estatus == 'Abierta' && fechaBD != fechaHoy) {
                mostrarMensajeCerrarCaja(fechaHoy, dateBD);
                return 0;
            }

        } catch (error) {
            console.error(error);
        }
        /* JMMC SISDESA */

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        })
        if (respuesta) {
            try {
                const response = await API.delete(URLVENTASELIMINAR + id);
                if (response.status === 200) {
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page => page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error, document.getElementById("respuesta"));
            }

        }

    }
    const imprimir = async (id) => {
        try {
            const response = await API.get(URLVENTASTICKET + id);
            if (response.status === 200) {
                var venta = response.data;
                funciones.imprimirVenta(venta, logo);

            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));
            }

        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
        }


    }
    return (
        <>
            <Titulo titulo="Ventas" tituloBajo="" excel={true} rutaExcel="auth/ventas/generarExcel"></Titulo>

            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">


                        {/* 
                        <div className="form-row">
                            <div className="col-6" >
                                <label htmlFor="IMEI">IMEI/Serie*(Últimos 6 digitos)</label>
                                <input type="text" className="form-control" id="IMEI" name="IMEI" ref={register({ required: true })} />
                            </div>
                        </div> */}

                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            {/* <h4>Ventas</h4> */}

                            <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarVenta" fechas={true} ></Busqueda>
                        </div>
                        <div className="pb-5">
                            <div className="table-responsive">
                                {cargando ?
                                    <div className="text-center pt-5">
                                        <h4>Cargando...</h4>
                                    </div>
                                    :
                                    <>
                                        {
                                            ventas.length > 0 ?
                                                <>
                                                    <table className="table mb-0 table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Folio</th>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Empleado</th>
                                                                <th scope="col">Cantidad</th>
                                                                <th scope="col">Total</th>
                                                                <th scope="col">Fecha registro</th>
                                                                <th scope="col">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                ventas.map((object, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {
                                                                                object.FOLIO
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                object.Tipo
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {object.NombreCliente}
                                                                        </td>
                                                                        <td>
                                                                            {object.NombreEmpleado}
                                                                        </td>
                                                                        <td>{object.Cantidad}</td>
                                                                        <td>{"$" + object.Total}</td>
                                                                        <td>{object.created_at}</td>
                                                                        <td>
                                                                            {
                                                                                object.Tipo == 'Venta'
                                                                                    ?
                                                                                    <>
                                                                                        {/* ----------------------------- VENTAS ---------------------------------- */}
                                                                                        {
                                                                                            permisos.Actualizar === 1 && permisos.Visualizar === 1 ?
                                                                                                <Link className="" name="id" to={`/consultar-venta/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                        {
                                                                                            object.Pagado === 1 ?
                                                                                                //    <button className="btn btn-info ml-3"  onClick={()=>imprimir(object.id)} type="button"
                                                                                                //    >Imprimir</button> 
                                                                                                <Link to="#"><img src={iconoPrint} style={{ height: "23px", marginTop: "-5px" }} onClick={() => imprimir(object.id)} alt="imprimir" className="pl-3" ></img></Link>
                                                                                                :
                                                                                                <i className="pl-3" style={{ width: "37px" }}></i>
                                                                                        }
                                                                                        {
                                                                                            permisos.Eliminar === 1 ?
                                                                                                <Link className="ml-3" to="#" onClick={() => eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {/* ----------------------------- SERVICIOS ---------------------------------- */}
                                                                                        {
                                                                                            permisos.Visualizar === 1 ?
                                                                                                <Link className="" name="id" to={`/ver-servicio/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                        {
                                                                                            permisos.Actualizar === 1 ?
                                                                                                <Link className="ml-3" name="id" to={`/editar-servicio/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                        {
                                                                                            permisos.Eliminar === 1 ?
                                                                                                <Link className="ml-3" to="#" onClick={() => eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                                                </>
                                                :
                                                <div className="text-center pt-5">
                                                    <h4>Sin resultados...</h4>
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                    </div>
                </div>
            </div >
        </>


    )
}

export default Ventas;