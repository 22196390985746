import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm, Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link,useParams  } from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'


const AgregarAjuste =() => {
    const URLAJUSTESLEER = 'auth/inventarios/trnmovimientos-mostrar/'
    let { id } = useParams();
    const ajuste  =obtenerData(URLAJUSTESLEER,id,1)
    const { register, handleSubmit, errors, setValue, control  } = useForm()

    // const traerProductos =async () => {
    //     const productos= await obtenerCatalogo('auth/inventarios/productos-catalogo')
    //     setProductos(productos);
    //     setCargandop(false)
    //     setValue("catproducto_id",{value: null, label: '--Selecciona un valor--'})
    // }
    // const traerTiposMovimeinto =async () => {
    //     const tiposmovimiento= await obtenerCatalogo('auth/movimientos/movimientos-catalogo')
    //     setTipomovimiento(tiposmovimiento);
    //     setCargandotp(false)
    //     setValue("cattipomovimiento_id",{value: null, label: '--Selecciona un valor--'})
    // }
    // const traerAlmacenes =async () => {
    //     const almacenes= await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogo')
    //     if(almacenes || almacenes.length===0){
    //         almacenes.unshift({value: "", label: '--Selecciona un valor--'})
    //         setAlmacenes(almacenes);
    //     }else{
    //         setAlmacenes({value: "", label: '--Selecciona un valor--'})
    //     }
        
    // }


    return (
    <>
    <Titulo titulo="Ver ajuste inventario" tituloBajo="Ajustes inventario &gt; Ver ajuste inventario"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Ajustes inventario</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label htmlFor="catproducto_id">Producto*</label>
                            <label htmlFor="catproducto_id" className="form-control">{ajuste.catproducto ? ajuste.catproducto.Nombre : "N/A"}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cattipomovimiento_id">Tipo movimiento*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.cattipomovimiento ? ajuste.cattipomovimiento.Nombre : "N/A"}</label>

                        </div>
                        <div className="form-group">
                            <label htmlFor="catalmacen_id">Almacen*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.cat_almacen ? ajuste.cat_almacen.Nombre : "N/A"}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Cantidad">Cantidad*</label>
                            <label htmlFor="cattipomovimiento_id" className="form-control">{ajuste.Cantidad }</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="descripcion">Descripción*</label>
                            <label htmlFor="cattipomovimiento_id" style={{minHeight: "30px"}} className="form-control">{ajuste.Descripcion }</label>
                        </div>
                        <div id="respuesta" ></div>
                        <div  className="text-right">
                            <Link to="/ajustes" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarAjuste;