import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';


const EditarSucursal =() => {
    const [empleados,setEmpleados] = useState({})
    const [empleado,setEmpleado] = useState({})
    const [cargando,setCargado] = useState(true)
    const URLSUCURSALESLEER = 'auth/sucursales/sucursales-mostrar/'
    let { id } = useParams();
    const sucursal  =obtenerData(URLSUCURSALESLEER,id,1)
    const { register, handleSubmit, errors,setValue,control  } = useForm()

    
    const traerEmpleados =async (sucursal) => {
        const empleados= await obtenerCatalogo('auth/nominas/empleados-catalogo')
        setEmpleados(empleados);
        setCargado(false)
        var valor=sucursal.catempleado_id;
        var empleado=empleados.filter(emple => emple.value===valor)[0];
        if(empleado){
            setEmpleado(empleado)
            setValue("catempleado_id", empleado);
        }else{
            setEmpleado({value: null, label: '--Selecciona un valor--'})
            setValue("catempleado_id",{value: null, label: '--Selecciona un valor--'})
        }
    
    }
    useEffect(
        ()=>{
        const asignar =(sucursal) =>{
                if(sucursal.id)
                traerEmpleados(sucursal);
                setValue('Nombre', sucursal.Nombre)
                setValue('Direccion', sucursal.Direccion)
                setValue('Descripcion', sucursal.Descripcion)
                setValue('Telefono', sucursal.Telefono)
            }
        asignar(sucursal)
        }
    ,[setValue,sucursal]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            if(data.catempleado_id.value){
                data.catempleado_id = data.catempleado_id.value;
            }else{
                data.catempleado_id = null;

            }
            const response =  await API.put('auth/sucursales/sucursales-actualizar/'+sucursal.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "La registro se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/sucursales')
            }else{
                funciones.tratarerrores(response,document.getElementById("respuesta"));
                document.getElementById("enviar").disabled = false;
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    const handleChangeEmpleado = e =>{
        setValue("catempleado_id", e);
        setEmpleado(e)
    }
    return (
    <>
    <Titulo titulo="Editar sucursal" tituloBajo="Sucursal &gt; editar sucursal"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Sucursal</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true, maxLength: 60 })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y su longitud no puede ser mayor a 60!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="rol">Encargado*</label>
                            {cargando ?
                            "Cargando"
                            :
                            <Controller
                                name="catempleado_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeEmpleado}
                                    options={empleados}
                                    isDisabled={false}
                                    value={empleado}
                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Telefono">Teléfono*</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true, maxLength: 40 })}  />
                            {errors.Telefono &&
                            <div className="error-form text-red p-small">
                                Favor de llenar el campo
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Direccion">Dirección*</label>
                            <input type="text" className="form-control" id="Direccion" name="Direccion" ref={register({ required: true, maxLength: 50 })}  />
                            {errors.Direccion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y su longitud no puede ser mayor a 50!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/sucursales" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarSucursal;