import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const EditarCategoriaProducto =() => {
    const URLCATEGORIALEER = 'auth/inventarios/categorias-mostrar/'
    let { id } = useParams();
    const CategoriaProducto  =obtenerData(URLCATEGORIALEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(CategoriaProducto) =>{
            setValue('Nombre', CategoriaProducto.Nombre)
            setValue('Descripcion', CategoriaProducto.Descripcion)
            setValue('Comision', CategoriaProducto.Comision)
            setValue('Codigo', CategoriaProducto.Codigo)
            }
        asignar(CategoriaProducto)
        }
    ,[setValue,CategoriaProducto]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.put('auth/inventarios/categorias-actualizar/'+CategoriaProducto.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "La categoria se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/CategoriaProductos')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Editar categoría productos" tituloBajo="Categorías productos &gt; Editar categoría productos"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Categoria producto</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre categoria*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Codigo">Código*</label>
                            <input type="text" className="form-control" id="Codigo" name="Codigo" ref={register({ required: true })}  />
                            {errors.Codigo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Comision">Comision % (1 al 100)*</label>
                            <input type="number" className="form-control" id="Comision" name="Comision" ref={register({max: 100, min: 1})}  />
                            {errors.Comision &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debe estar entre 1 y 100!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/CategoriaProductos" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarCategoriaProducto;