import React, {useEffect, useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const VerCliente =() => {
    const URLCATEGORIALEER = 'auth/ventas/clientes-mostrar/'
    let { id } = useParams();
    const cliente  =obtenerData(URLCATEGORIALEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()
    const [estados,setEstados] = useState({})
    const [municipios,setMunicipios] = useState({})
    const [sucursales,setSucursales] = useState({})
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());

    const traerMunicpios  =async (cliente) => {
        const response =   await API.post('auth/municipios/catalogo',funciones.getFormData({"catestado_id":cliente.catestado_id}))
        const data = await response.data
        if(data){
            data.unshift({value: "", label: '--Selecciona un valor--'})
            setMunicipios(data);
        }else{
            setMunicipios({value: "", label: '--Selecciona un valor--'})
        }
    }
    const traerEstados =async () => {
        var estados= await obtenerCatalogoSinDefecto('auth/estados/catalogo')
        if(estados){
            estados.unshift({value: "", label: '--Selecciona un valor--'})
            setEstados(estados);
        }else{
            setEstados({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerSucursales =async () => {
        const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
        if(sucursales || sucursales.length===0){
            sucursales.unshift({value: "", label: '--Selecciona un valor--'})
            setSucursales(sucursales);
        }else{
            setSucursales({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    useEffect(
        ()=>{
        const asignar =(cliente) =>{
            if(cliente.id){
                setValue('Telefono', cliente.Telefono)
                setValue('Nombre', cliente.Nombre)
                // setValue('Razon', cliente.Razon)
                // setValue('RFC', cliente.RFC)
                setValue('Sexo', cliente.Sexo)
                setValue('Edad', cliente.Edad)
                setValue('CP', cliente.CP)
                // setValue('Colonia', cliente.Colonia)
                // setValue('Calle', cliente.Calle)
                // setValue('Numext', cliente.Numext)
                // setValue('Email', cliente.Email)
                traerSucursales();
                if(cliente.FechaNacimiento==null){
                    setFechaNacimiento(new Date())
                }else{
                    setFechaNacimiento(new Date(cliente.FechaNacimiento.replace(/-/g, '\/')))
                }
                // traerEstados();
                // traerMunicpios(cliente);
            }
            }
        asignar(cliente)
        }
    ,[setValue,cliente]);

    const handleChangeEstado = async e =>{
        try {
            const response =   await API.post('auth/municipios/catalogo',funciones.getFormData({"catestado_id":e.target.value}))
            const data = await response.data
            if(data){
                data.unshift({value: "", label: '--Selecciona un valor--'})
                setMunicipios(data);
            }else{
                setMunicipios({value: "", label: '--Selecciona un valor--'})
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
    <>
    <Titulo titulo="Ver cliente" tituloBajo="Clientes &gt; ver cliente"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Datos de cliente</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="Razon">Razon</label>
                            <input type="text" className="form-control" id="Razon" name="Razon" ref={register}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="RFC">RFC</label>
                            <input type="text" className="form-control" id="RFC" name="RFC" ref={register}  />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="catsucursal_id">Sucursal*</label>
                            <select className="form-control" name="catsucursal_id" id="catsucursal_id" ref={register({ required: true })} >
                                {
                                    sucursales.length >0 ?
                                    sucursales.map((object, index) => (
                                        cliente.id ?
                                        cliente.catsucursal_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        

                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>  
                        <div className="form-group">
                            <label htmlFor="Telefono">Teléfono</label>
                            <input type="text" className="form-control" id="Telefono" name="Telefono" value={cliente.Telefono} ref={({ required: true })}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Sexo">Sexo</label>
                            <select className="form-control" name="Sexo" id="Sexo" ref={register} >
                                <option value="H">Hombre</option>
                                <option value="M">Mujer</option>
                            </select>
                        </div>     
                        <div className="form-group">
                            <label htmlFor="Email">Correo</label>
                            <input type="email" className="form-control" id="Email" name="Email" ref={register}  />
                        </div>
                        {/* <div className="form-row" >
                            <div className="col-6 form-group">
                                <label htmlFor="catestado_id">Estado*</label>
                                <select className="form-control" 
                                onChange={handleChangeEstado}   name="catestado_id" id="catestado_id" ref={register} >
                                {
                                    estados.length >0 ?
                                    estados.map((object, index) => (
                                        cliente.id ?
                                        cliente.catestado_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        
                                    ))
                                    :
                                    ""
                                                            
                                }
                                </select>
                            </div>
                            <div className="col-6 form-group">
                                <label htmlFor="catmunicipio_id">Municipios*</label>
                                <select className="form-control" name="catmunicipio_id" id="catmunicipio_id" ref={register} >
                                {
                                    municipios.length >0 ?
                                    municipios.map((object, index) => (
                                        cliente.id ?
                                        cliente.catmunicipio_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""               
                                }
                                </select>
                            </div>                           
                        </div>
                        <div className="form-group">
                            <label htmlFor="Colonia">Colonia</label>
                            <input type="text" className="form-control" id="Colonia" name="Colonia" ref={register}  />
                        </div>
                        <div className="form-group" >
                            <label htmlFor="Calle">Calle</label>
                            <input type="text" className="form-control" id="Calle" name="Calle" ref={register}  />
                        </div> */}
                        <div className="form-row" >
                            <div className="col-6">
                                <label htmlFor="CP">CP</label>
                                <input type="text" className="form-control" id="CP" name="CP" ref={register({maxLength: 10, pattern: /[0-9]/i})}  />
                            </div>
                            <div className="col-6">
                                <label htmlFor="Sexo">Fecha nacimiento</label>
                                <div style={{width:"100%;"}}>
                                    <DatePicker
                                        selected={fechaNacimiento}
                                        dateFormat="yyyy-MM-dd"
                                        id="FechaNacimiento"
                                        className="form-control"
                                        name="FechaNacimiento"
                                        onChange={date=>setFechaNacimiento(date)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/clientes" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerCliente;