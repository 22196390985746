import React, { useState, useCallback } from 'react'
import PaginacionSinNumeros from './PaginacionSinNumeros'
import obtenerDatos from './../librerias/obtenerDatos'
export default ({ titulo, urlBusqueda, elegirElemento, idModal }) => {
    const URLCLIENTES = urlBusqueda
    const { cargando, data: clientes, paginas, cambiarPagina, buscarData, current } = obtenerDatos(URLCLIENTES);
    const [buscar, setBuscar] = useState('');

    const buscarDataPalabra = useCallback(
        () => {
            buscarData(buscar);
        },
        [buscar, buscarData],
    );
    const click = () => {
        window.open("/?ruta=cliente")
    }
    const busacarEnter = (e) => {
        var keycode = e.keyCode || e.which;
        if (keycode == 13) buscarDataPalabra();
    }
    return (
        <>
            {/* Modal */}
            <div className="modal fade" id={idModal} tabIndex="-1" role="dialog" aria-labelledby={`${idModal}CenterTitle`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{titulo} |
                                <button onClick={() => { click() }} className="btn btn-primary">Agregar cliente</button >
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-row" >
                                <div className="col-12 mb-3" >
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Nombre" onKeyUp={e => busacarEnter(e)} onChange={e => setBuscar(e.target.value)} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" onClick={buscarDataPalabra} >Buscar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {cargando ?
                                        <div className="text-center pt-5">
                                            <h4>Cargando...</h4>
                                        </div>
                                        :
                                        <>
                                            {
                                                clientes.length > 0 ?
                                                    <>
                                                        <table className="table mb-0 table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Acción</th>
                                                                    <th scope="col">Nombre</th>
                                                                    <th scope="col">Teléfono</th>
                                                                    {/* <th scope="col">RFC</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    clientes.map((object, index) => (
                                                                        <tr style={{cursor:'pointer'}} key={index} onDoubleClick={() => {
                                                                            document.getElementById("cerrar-mocal-cliente").click();
                                                                            elegirElemento({ id: object.id, nombre: object.Nombre })
                                                                        }} >
                                                                            <td> <button className="btn btn-primary"
                                                                                onClick={() => {
                                                                                    document.getElementById("cerrar-mocal-cliente").click();
                                                                                    elegirElemento({ id: object.id, nombre: object.Nombre })
                                                                                }}
                                                                            >Seleccionar</button> </td>

                                                                            <td>{object.Nombre}</td>
                                                                            <td>{object.Telefono}</td>
                                                                            {/* <td>{ object.RFC }</td> */}
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <PaginacionSinNumeros paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></PaginacionSinNumeros>
                                                    </>
                                                    :
                                                    <div className="text-center pt-5">
                                                        <h4>Sin resultados...</h4>
                                                    </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="cerrar-mocal-cliente" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
