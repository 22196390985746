import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import obtenerValorcfg from '../../../librerias/obtenerValorcfg'


const AgregarCompra =() => {
    const clsproducto = {
        catproducto_id:  {value: null, label: '--Selecciona un valor--'},
        Precio: "",
        Cantidad: 1,
        Total : "",
        Almacen : "",
    };
    const { register, handleSubmit, errors,setValue, control  } = useForm()
    const [productoselect,setProductooselect] = useState({})
    const [productoselectC,setProductooselectC] = useState(true)
    const [provedores,setProvedores] = useState({})
    const [productos,setProductos] = useState([clsproducto])
    const [provedorec,setProvedorec] = useState(true)
    const [iva,setiva] = useState(16)
    const [almacenes,setAlmacenes] = useState({})
    const ivaID=1;




    const traerIVA =async () => {
        const iva= await obtenerValorcfg('auth/cfg-ver/'+ivaID)
        setiva(iva.Valor);
        
    }
    const traerAlmacenes =async () => {
        const almacenes= await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogotodos')
        if(almacenes || almacenes.length===0){
            almacenes.unshift({value: "", label: '--Selecciona un valor--'})
            setAlmacenes(almacenes);
        }else{
            setAlmacenes({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerProvedores =async () => {
        const provedores= await obtenerCatalogo('auth/inventarios/proveedores-catalogo')
        setProvedores(provedores);
        setProvedorec(false)
        setValue("catproveedor_id",{value: null, label: '--Selecciona un valor--'})  
    }
    const traerProductos =async () => {
        const productoselect= await obtenerCatalogo('auth/inventarios/productos-catalogo')
        setProductooselect(productoselect);
        setProductooselectC(false)
        setValue("catproducto_id",{value: null, label: '--Selecciona un valor--'})
    }
    const validar =(productos) =>{
        var bandera = false;
        var banderaPreciosCantidades = false;
        var banderaProductos = false;
        productos.forEach((produc,index)=>{
            if(produc.Precio<=0 || produc.Cantidad<=0 ) 
                banderaPreciosCantidades = true;
            if(produc.catproducto_id.value == null)
                banderaProductos =true;
        })
        if(banderaPreciosCantidades){
            document.getElementById("respuesta").innerHTML ="Todos los precios y cantidades deben ser mayor a 0"
        }
        if(banderaProductos){
            document.getElementById("respuesta").innerHTML ="Debes seleccionar un producto en todas las partidas"
        }
        bandera = !banderaProductos && !banderaPreciosCantidades ? true : false;
        return bandera;
    }
    const onSubmit =async (data,e) => { 
    try {
        var bandera =data.catproveedor_id.value==null ?  false : true;
        if(!bandera){
            document.getElementById("respuesta").innerHTML ="Debes seleccionar un provedor."
        }else if(validar(productos)){
            data.catproveedor_id = data.catproveedor_id.value
            data.productos = productos;
            const response =   await API.post('auth/inventarios/compras-agregar', data);
            if(response.status===201){
                swal({
                    title: "Registrado",
                    text: "El ajuste se llevo de manera correctamente",
                    icon: "success",
                    button: "Aceptar"
                }); 
                document.getElementById("enviar").disabled = false;
                history.push('/compras')
            
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        }

        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
        }

    }
    useEffect(
        ()=>{
            traerProvedores();
            traerProductos();
            traerAlmacenes();
            traerIVA();
        }
    ,[]);
    const handleChangeProvedor = e => {
        setValue("catproveedor_id", e);
    }
    const handleChangeProducto = (e,index) => {
        // console.log(e)
        handleCambiar(e.value,"Producto",index)
    }
    const addProducto = () => {
        const clsproductoD = {
            catproducto_id:  {value: null, label: '--Selecciona un valor--'},
            Precio: "",
            Cantidad: 1,
            Total : "",
            Almacen : "",
        };
        setProductos(productos => [...productos, clsproductoD]);
    };
    const removeProducto = indexx => () => {
        setProductos(productos => [...productos.filter((producto, index) =>     (   indexx !==index     ))]);
      };
    const calcularTotal = (produc) =>{
        return produc.Cantidad*produc.Precio;
    }
    const handleCambiar = async (valor,concepto,indexx) =>{
        try {
            var  proNuevos = [];
            var RE = /^\d*\.?\d*$/;
            if (!RE.test(valor)) {
                return false;
            }
            switch (concepto) {
                case "Precio":
                    proNuevos= productos.map((producto,index )=>  {

                        if(indexx ===index){
                            producto.Precio = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Cantidad":
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Cantidad = valor
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Producto":
                    var response =   await API.get('auth/inventarios/productos-mostrar/'+valor)
                    var productoData = await response.data
                    
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Precio = productoData.Costo;
                            producto.catproducto_id = { value:valor, label:productoData.Nombre }
                            // console.log(producto)
                            producto.Total = calcularTotal(producto)
                        }
                        return producto;
    
                    })
                break;
                case "Almacen":
                    proNuevos= productos.map((producto,index )=>  {
    
                        if(indexx ===index){
                            producto.Almacen = valor
                        }
                        return producto;
    
                    })
                break;
                default:
                    break;
            }
            setProductos([...proNuevos]);
            
        } catch (error) {
            console.log("error",error)
        }  
    }

    return (
    <>
    <Titulo titulo="Agregar compra" tituloBajo="Compras &gt; Agregar compra"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Realizar compra</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="catproveedor_id">Proveedor*</label>
                            {provedorec ?
                            "Cargando"
                            :
                            <Controller
                                name="catproveedor_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeProvedor}
                                    options={provedores}
                                    isDisabled={false}
                                    />
                                )}
                            />
                            }
                        </div>
                        <span className="d-none" >Cargando precios y montos...</span>
                        {
                            productoselectC ? 
                            "Cargando"
                            :
                            productos.map((object, index) => {
                                const fieldName = `catproducto_id[${index}]`;
                                return (                               
                                <div className="form-group form-row" key={index}>
                                    <div className="col-4" >
                                        <label htmlFor={`${fieldName}.catproducto_id`}>Producto*</label>
                                        <Controller
                                        name={`${fieldName}.catproducto_id`}
                                        control={control}
                                        render={({ onChange, onBlur }) => (
                                                <Select
                                                placeholder='--Selecciona un valor--'
                                                onChange={(e)=>{handleChangeProducto(e,index)}}
                                                options={productoselect}
                                                value={productos[index].catproducto_id}
        
                                                />
                                            )}
                                        />
                                         {/* handleCambiar(e,"Precio",index) */}
                                    </div>
                                    <div className="col-2" >
                                        <label htmlFor={`${fieldName}.Precio`}>Precio.*</label>
                                        <input  type="text"  className="form-control" id={'Precio'+index} name={`${fieldName}.Precio`} value={productos[index].Precio} onChange={ (e)=>{handleCambiar(e.target.value,"Precio",index)}} required />
                                    </div>
                                    <div className="col-2" >
                                        <label htmlFor={`${fieldName}.Cantidad`}>Cant.*</label>
                                        <input  type="text" className="form-control" id={'Cantidad'+index}   name={`${fieldName}.Cantidad`} value={productos[index].Cantidad} onChange={ (e)=>{handleCambiar(e.target.value,"Cantidad",index)}}  required />
                                    </div>
                                    <div className="col-2" >
                                        <label htmlFor={`${fieldName}.Total`}>Total*</label>
                                        <input type="text" className="form-control" id={'Total'+index} name={`${fieldName}.Total`} value={productos[index].Total}  readOnly="true" required />
                                    </div>
                                    <div className="col-2" >
                                    <label htmlFor="catalmacen_id">Almacen*</label>
                                    {
                                        index!=0 ?
                                        <button type="button" aria-label="Close"  onClick={removeProducto(index)}  style={{background: "red",borderRadius: "65px",width: "23px",fontWeight :"600 !important"}} className="ml-2 mb-1 close">
                                            <span style={{color: "black"}}  >×</span>
                                        </button>
                                        :
                                        ""
                                    }
                                    <select className="form-control" name={`${fieldName}.Total`}  id={'Almacen'+index} required value={productos[index].Almacen}  onChange={ (e)=>{handleCambiar(e.target.value,"Almacen",index)}} >
                                        {
                                            almacenes.length >0 ?
                                            almacenes.map((object, index) => (
                                                <option value={object.value}  key={index}>{object.label}</option>
                                            ))
                                            :
                                            ""
                                        }
                                    </select>
                                    </div>
                                </div>
                                )
                            })
                        }
                        <div className="col-md-12 pt-5 p-0">
                            <a href="javascript:void(0);" onClick={addProducto} className="btn-block alert alert-outline-primary dashed text-center" >Agregar otro producto</a>
                        </div>
                        <div className="form-row" >
                            <div className="form-group col-8">
                                <label htmlFor="descripcion">Nota*</label>
                                <textarea className="form-control" id="Nota" name="Nota" rows="3" ref={register({ required: true })}></textarea>
                                {errors.Nota &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>

                            <div className="col-4" >
                                <table>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td >
                                            {       
                                               productos.reduce((sum, li) => sum + li.Total, 0)
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Iva</td>
                                        <td >
                                            {       
                                            
                                               (productos.reduce((sum, li) => sum + li.Total, 0)*iva/100)
                                           
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td >
                                            {
                                                ((productos.reduce((sum, li) => sum + li.Total, 0)*iva/100)+ productos.reduce((sum, li) => sum + li.Total, 0))
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/compras" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarCompra;