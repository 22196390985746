import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm, Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'



const AgregarMovimiento =() => {
    const [cargandop,setCargandop] = useState(true)
    const [productos,setProductos] = useState({})
    const [almacenes,setAlmacenes] = useState({})
    const { register, handleSubmit, errors, setValue, control  } = useForm()

    const traerProductos =async () => {
        const productos= await obtenerCatalogo('auth/inventarios/productos-catalogo')
        setProductos(productos);
        setCargandop(false)
        setValue("catproducto_id",{value: null, label: '--Selecciona un valor--'})
    }
    const traerAlmacenes =async () => {
        const almacenes= await obtenerCatalogoSinDefecto('auth/inventarios/almacenes-catalogotodos')
        if(almacenes || almacenes.length===0){
            almacenes.unshift({value: "", label: '--Selecciona un valor--'})
            setAlmacenes(almacenes);
        }else{
            setAlmacenes({value: "", label: '--Selecciona un valor--'})
        }
    }

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            if(data.catalmacen_id_destino===data.catalmacen_id_origen){
                document.getElementById("enviar").disabled = false;
                document.getElementById("respuesta").innerHTML ="Favor de seleccionar diferentes almacenes.";
                return;
            }
            if(data.catproducto_id.value){
                data.catproducto_id = data.catproducto_id.value;
                const response =   await API.post('auth/inventarios/trnmovimientosalm-agregar', data);
                if(response.status===201){
                    swal({
                        title: "Registrado",
                        text: "El ajuste se llevo de manera correctamente",
                        icon: "success",
                        button: "Aceptar"
                    }); 
                    document.getElementById("enviar").disabled = false;
                    history.push('/movimientoalmacenes')
              
                }else{
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response,document.getElementById("respuesta"));
                }
            }else{
                document.getElementById("enviar").disabled = false;
                document.getElementById("respuesta").innerHTML ="Favor de seleccionar un producto y un tipo de movimiento";
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
             document.getElementById("enviar").disabled = false;
          }
  
       }
    const handleChangeProd = e => {
        setValue("catproducto_id", e);
    }
    useEffect(
        ()=>{
            traerProductos();
            traerAlmacenes();
        }
    ,[]);
    return (
    <>
    <Titulo titulo="Agregar movimiento" tituloBajo="Movimientos &gt; Agregar movimiento"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Movimientos</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="catproducto_id">Producto*</label>
                            {cargandop ?
                            "Cargando"
                            :
                            <Controller
                                name="catproducto_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeProd}
                                    options={productos}
                                    isDisabled={false}

                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catalmacen_id_origen">Almacen origen*</label>
                            <select className="form-control" name="catalmacen_id_origen" id="catalmacen_id_origen"   ref={register({ required: true })} >
                                {
                                    almacenes.length >0 ?
                                    almacenes.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catalmacen_id_origen &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catalmacen_id_destino">Almacen Destino*</label>
                            <select className="form-control" name="catalmacen_id_destino" id="catalmacen_id_destino"   ref={register({ required: true })} >
                                {
                                    almacenes.length >0 ?
                                    almacenes.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                                            
                                }
                            </select>
                            {errors.catalmacen_id_destino &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Cantidad">Cantidad*</label>
                            <input type="number" className="form-control" id="Cantidad" name="Cantidad" ref={register({ required: true })}  />
                            {errors.Cantidad &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debé ser un campo numérico!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div  className="text-right">
                            <Link to="/movimientoalmacenes" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarMovimiento;