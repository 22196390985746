import React, { useState } from 'react';
import Titulo from '../../../componentes/Titulo'
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import { Link } from "react-router-dom";
import API from '../../../librerias/api';

const AgregarEntradaSalida = () => {
    const { handleSubmit, errors } = useForm()
    const [campos, setCampos] = useState({ tipo: '', monto: 0, montoEntero: 0, montoDec: 0, observaciones: '' })

    const validarCampoDec = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            let montoCompleto = Number(`${campos.montoEntero}.${valor}`);
            let nuevosCampos = campos;
            nuevosCampos.montoDec = valor;
            nuevosCampos.monto = montoCompleto;
            setCampos({ ...campos, nuevosCampos });
        }
    }

    const validarCampoEntero = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            let montoCompleto = Number(`${valor}.${campos.montoDec}`);
            let nuevosCampos = campos;
            nuevosCampos.montoEntero = valor;
            nuevosCampos.monto = montoCompleto;
            setCampos({ ...campos, nuevosCampos });
        }
    }

    const setObservaciones = (valor) =>{
        let nuevosCampos = campos;
        nuevosCampos.observaciones = valor;
        setCampos({ ...campos, nuevosCampos });
    }

    const enviar = async (data, e) => {
        try {
            console.log(campos);
            if (0 >= campos.monto) {
                return swal({
                    title: "MONTO INVALIDO",
                    text: `El "Monto" no puede ser menor o igual a 0.`,
                    icon: "warning",
                    button: "Aceptar"
                    // buttons: ["Cancelar", "Aceptar"],
                });
                // .then(willDelete => {
                //     if (willDelete) {
                //       swal("Deleted!", "Your imaginary file has been deleted!", "success");
                //     }
                //   });
            }
            let tipo = document.getElementById('TipoG').value == 'I' ? 'entrada' : 'salida';
            let formData = new FormData(document.getElementById("formulario"));
            formData.append("MontoG", campos.monto)
            const response = await API.post('auth/gastos/guardar', formData);
            console.log(response);
            if (response.status === 201) {
                swal({
                    title: "REGISTRADO",
                    text: `La ${tipo} se realizó correctamente.`,
                    icon: "success",
                    button: "Aceptar"
                });
                history.push('/cierresDeCaja')
            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));

            }
        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
            if (document.getElementById("enviar"))
                document.getElementById("enviar").disabled = false;
        }
    }

    return (
        <>
            <Titulo titulo="Entrada o salida" tituloBajo="Cierre de caja &gt; Agregar entrada o salida"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="row" >
                            {/* <div className="widget-header bordered no-actions d-flex align-items-center justify-content-between">
                                {
                                    <h4>Agregar entrada o salida de efectivo</h4>
                                }
                            </div> */}
                        </div>
                        <div className="widget-body" >
                            {
                                <form id="formulario" onSubmit={handleSubmit(enviar)}>
                                    <div className="form-row" >
                                        <div className="col-8" >
                                            <div className="form-group">
                                                <label htmlFor="catsucursal_id">Tipo*</label>
                                                <select className="form-control" id="TipoG" name="TipoG" required >
                                                    <option value="" disabled selected>--Selecciona un valor--</option>
                                                    <option value="I">Entrada</option>
                                                    <option value="E">Salida</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Monto recibido</label>
                                                <div className="row pl-3" >
                                                    <input type="text" className="form-control col-7" onChange={(e) => { validarCampoEntero(e.target.value) }} value={campos.montoEntero} />
                                                    <label style={{ color: "black", fontSize: "24px", maxHeight: "33.5px", margin: "0" }} className="text-center px-1">.</label>
                                                    <input type="text" className="form-control col-4" onChange={(e) => { validarCampoDec(e.target.value) }} value={campos.montoDec} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="descripcion">Observaciones</label>
                                        <textarea class="form-control" id="Observaciones" name="ObservacionesG" rows="3"  onChange={(e) => { setObservaciones(e.target.value) }} value={campos.campos}></textarea>
                                    </div>
                                    <div id="error-agregar" ></div>
                                    <div className="text-right">
                                        <Link to="/cierresDeCaja" className="btn btn-secondary">Atras</Link>
                                        <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                                    </div>
                                </form>
                            }
                            <div id="respuesta" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AgregarEntradaSalida;