import React, { useState, useCallback } from 'react'
import PaginacionSinNumeros from './PaginacionSinNumeros'
import obtenerDatos from './../librerias/obtenerDatos'
export default ({ titulo, urlBusqueda, elegirElemento, idModal }) => {
    const URLINVENTARIOUSUARIO = urlBusqueda
    const { cargando, data: productos, paginas, cambiarPagina, buscarData, current } = obtenerDatos(URLINVENTARIOUSUARIO);
    const [buscar, setBuscar] = useState('');
    try {
        const traerDatas = async () => {
            try {
                console.log(productos);
                // const response = await API.post(URLSERVICIO);
                // if (response.status === 200) {
                //     // console.log(response)
                //     var usarioHeader = response.data;
                //     console.log(usarioHeader)
                // }
            } catch (error) {
                console.log('a');
            }
        }
        traerDatas();
    } catch (error) {
        console.log('a');
    }

    const buscarDataPalabra = useCallback(
        () => {
            buscarData(buscar);
        },
        [buscar, buscarData],
    );
    const busacarEnter = (e) => {
        var keycode = e.keyCode || e.which;
        if (keycode == 13) buscarDataPalabra();
    }
    return (

        <>
            {/* Modal */}
            <div className="modal fade" id={idModal} tabIndex="-1" role="dialog" aria-labelledby={`${idModal}CenterTitle`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{titulo}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-row" >
                                <div className="col-12 mb-3" >
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Código, Nombre" onKeyUp={e => busacarEnter(e)} onChange={e => setBuscar(e.target.value)} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" onClick={buscarDataPalabra} >Buscar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {cargando ?
                                        <div className="text-center pt-5">
                                            <h4>Cargando...</h4>
                                        </div>
                                        :
                                        <>
                                            {
                                                productos.length > 0 ?
                                                    <>
                                                        <table className="table mb-0 table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Acción</th>
                                                                    <th scope="col">Nombre</th>
                                                                    <th scope="col">Código</th>
                                                                    <th scope="col">Precio</th>
                                                                    <th scope="col">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    productos.map((object, index) => (
                                                                        <tr style={{cursor:'pointer'}} key={index} onDoubleClick={() => {

                                                                            elegirElemento(object)
                                                                            document.querySelectorAll(".cerrar-mocal-producto").forEach((element) => element.click())
                                                                        }}>
                                                                            <td> <button className="btn btn-primary"
                                                                                onClick={() => {

                                                                                    elegirElemento(object)
                                                                                    document.querySelectorAll(".cerrar-mocal-producto").forEach((element) => element.click())
                                                                                }}
                                                                            >Seleccionar</button> </td>

                                                                            <td>{object.Nombre}</td>
                                                                            <td>{object.Codigo}</td>
                                                                            <td style={{textAlign: 'left'}}>${object.Precio}</td>
                                                                            <td style={{textAlign: 'left'}}>${object.IVA.toFixed(2)}</td>

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <PaginacionSinNumeros paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></PaginacionSinNumeros>
                                                    </>
                                                    :
                                                    <div className="text-center pt-5">
                                                        <h4>Sin resultados...</h4>
                                                    </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cerrar-mocal-producto" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
