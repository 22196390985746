import React, { useState } from 'react';
import Titulo from '../../../componentes/Titulo'
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import { Link } from "react-router-dom";
import API from '../../../librerias/api';

const AbrirCaja = () => {
    const { register, handleSubmit, errors } = useForm();
    const [campos, setCampos] = useState({ monto: 0, montoEntero: 0, montoDec: 0 });

    const validarCampoDec = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            let montoCompleto = Number(`${campos.montoEntero}.${valor}`);
            let nuevosCampos = campos;
            nuevosCampos.montoDec = valor;
            nuevosCampos.monto = montoCompleto;
            setCampos({ ...campos, nuevosCampos });
        }
    }

    const validarCampoEntero = (valor) => {
        valor = valor
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (RE.test(valor)) {
            let montoCompleto = Number(`${valor}.${campos.montoDec}`);
            let nuevosCampos = campos;
            nuevosCampos.montoEntero = valor;
            nuevosCampos.monto = montoCompleto;
            setCampos({ ...campos, nuevosCampos });
        }
    }

    const enviar = async (data, e) => {
        try {
            document.getElementById("enviar").disabled = true;
            if (0 >= campos.monto) {
                document.getElementById("enviar").disabled = false;
                return swal({
                    title: "MONTO INVALIDO",
                    text: `El "Monto" no puede ser menor o igual a 0.`,
                    icon: "warning",
                    button: "Aceptar"
                });
            }
            let formData = new FormData(document.getElementById("formulario"));
            formData.append("MontoG", campos.monto)
            const response = await API.post('auth/cierreDeCaja/abrir', formData);
            console.log(response);
            if (response.status === 201) {
                let datos = response.data[0];
                console.log(datos);
                if (datos.CambioEstatus) {
                    swal({
                        title: "CAJA ABIERTA",
                        text: `La caja se abrió exitosamente.`,
                        icon: "success",
                        button: "Aceptar"
                    });
                    document.getElementById('img-perfil-1').classList.remove('perfil-caja-cerrada');
                    document.getElementById('img-perfil-1').classList.add('perfil-caja-abierta');
                    history.push('/cierresDeCaja');
                } else {
                    swal({
                        title: "CAJA ABIERTA",
                        text: `La caja ya se encuentra abierta, debes cerrarla primero para abrirla.`,
                        icon: "warning",
                        button: "Aceptar"
                    });
                    document.getElementById("enviar").disabled = false;
                }
            } else {
                funciones.tratarerrores(response, document.getElementById("respuesta"));
            }
        } catch (error) {
            funciones.tratarerrores(error, document.getElementById("respuesta"));
            if (document.getElementById("enviar"))
                document.getElementById("enviar").disabled = false;
        }
    }

    return (
        <>
            <Titulo titulo="Abrir caja" tituloBajo="Cierres y aperturas de caja &gt; Abrir caja"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="row" >
                            {/* <div className="widget-header bordered no-actions d-flex align-items-center justify-content-between">
                                {
                                    <h4>Agregar monto de apertura</h4>
                                }
                            </div> */}
                        </div>
                        <div className="widget-body" >
                            {
                                <form id="formulario" onSubmit={handleSubmit(enviar)}>
                                    <div className="form-row" >
                                        <div className="col-4" style={{ margin: 'auto' }}>
                                            <div className="form-group">
                                                <label>Monto apertura de caja</label>
                                                <div className="row pl-3" >
                                                    <input type="text" className="form-control col-7" onChange={(e) => { validarCampoEntero(e.target.value) }} value={campos.montoEntero} />
                                                    <label style={{ color: "black", fontSize: "24px", maxHeight: "33.5px", margin: "0" }} className="text-center px-1">.</label>
                                                    <input type="text" className="form-control col-4" onChange={(e) => { validarCampoDec(e.target.value) }} value={campos.montoDec} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="error-agregar" ></div>
                                    <div className="text-right">
                                        <Link to="/cierresDeCaja" className="btn btn-secondary">Atras</Link>
                                        <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                                    </div>
                                </form>
                            }
                            <div id="respuesta" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AbrirCaja;