import React, { useEffect } from 'react';
// import Titulo from '../../../componentes/Titulo'
// import API from '../librerias/api';
import history from '../librerias/history';
// import { useForm } from 'react-hook-form'
// import funciones from '../../../librerias/funciones'
// import {    useParams} from "react-router-dom";
import obtenerData from '../librerias/obtenerData'
import swal from 'sweetalert'
import funciones from '../librerias/funciones'
// import {    Link  } from "react-router-dom";

import bienvenido from './bienvenido.png';


const EditarCategoriaProducto = () => {
    // let { id } = useParams();
    const caja = obtenerData('auth/cierreDeCaja/Estatus', '', 1);

    useEffect(
        () => {
            const params = new URLSearchParams(window.location.search)
            if (params.get('ruta') === "cliente") {
                history.push('/AgregarCliente?clo=1')
            }
        }
        , []);

    const mostrarMensajeAbrirCaja = (fecha) => {
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA CERRADA",
            text: `¿Quieres abrir la caja?`,
            icon: "warning",
            // button: "Aceptar"
            buttons: ["No abrir caja", "Si abrir caja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                // swal("CAJA ABIERTA", "La caja ha sido abierta correctamente.", "success");
                history.push('/AbrirCaja');
            }
        });
    }

    const mostrarMensajeCerrarCaja = (fecha, fechaBD) => {
        localStorage.setItem('estatusCaja', 'none');
        localStorage.setItem('fechaCaja', fecha);
        swal({
            title: "CAJA ABIERTA",
            text: `La caja ha sido abierta el ${funciones.getFechaTexto(fechaBD)}, necesitas cerrarla para poder abrirla hoy.`,
            icon: "warning",
            // button: "Aceptar"
            buttons: ["No cerrar caja", "Si cerrar caja"]
        }).then(aperturaCaja => {
            if (aperturaCaja) {
                // swal("CAJA ABIERTA", "La caja ha sido abierta correctamente.", "success");
                history.push('/CerrarCaja');
            }
        });
    }

    const verificarCaja = async () => {
        try {
            if (!localStorage.getItem('estatusCaja')) {
                localStorage.setItem('estatusCaja', '');
                localStorage.setItem('fechaCaja', '');
            }
            /*---*/
            let itemEstatus = localStorage.getItem('estatusCaja');
            let itemFecha = localStorage.getItem('fechaCaja');
            let dateHoy = new Date();
            const fechaHoy = funciones.getFecha(dateHoy);
            if (itemEstatus == 'none' && itemFecha == fechaHoy) {
                return 0;
            }
            /*---*/
            if (caja[0].Estatus != 'Abierta') {
                mostrarMensajeAbrirCaja(fechaHoy);
                return 0;
            }
            /*---*/
            let dateBD = new Date(caja[0].FechaAperturaCC);
            const fechaBD = funciones.getFecha(dateBD);
            if (caja[0].Estatus == 'Abierta' && fechaBD != fechaHoy) {
                mostrarMensajeCerrarCaja(fechaHoy, dateBD);
            }

        } catch (error) {
            console.error(error);
        }
    }

    verificarCaja();

    const actualizarStorage = () => {
        localStorage.removeItem('estatusCaja');
        localStorage.removeItem('fechaCaja');
        console.log('se limpió');
    }

    // const onSubmit =async (data,e) => { 
    //     try {
    //         document.getElementById("enviar").disabled = true;
    //         const response =  await API.put('auth/inventarios/categorias-actualizar/'+CategoriaProducto.id, data);
    //         if(response.status===200){
    //            swal({
    //                 title: "Actualizado",
    //                 text: "La categoria se ha actualizado correctamente",
    //                 icon: "success",
    //                 button: "Aceptar"
    //            }); 
    //            document.getElementById("enviar").disabled = false;
    //            history.push('/CategoriaProductos')
    //         }else{
    //             document.getElementById("enviar").disabled = false;
    //             funciones.tratarerrores(response,document.getElementById("respuesta"));
    //         }
    //       } catch (error) {
    //         funciones.tratarerrores(error,document.getElementById("respuesta"));
    //         if(document.getElementById("enviar"))
    //          document.getElementById("enviar").disabled = false;
    //       }

    //    }
    return (
        <>

            <span className="" ></span>
            <div className="text-center col-12" style={{ paddingBottom: "166px" }}>
                {/* <img onClick={() => actualizarStorage()} src={bienvenido} /> */}
                <img src={bienvenido} />
            </div>


        </>

    )
}

export default EditarCategoriaProducto;