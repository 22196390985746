import React, { useState } from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { get, useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import { useParams } from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import { Link } from "react-router-dom";

const VerCierreDeVenta = () => {
    let { id } = useParams();
    const cierreDeCaja = obtenerData('auth/cierreDeCaja/obtener/', id, 1);
    const a = () => {
        console.log(cierreDeCaja);
    }
    const getTipo = (tipo, modulo) => {
        switch (tipo) {
            case 'A':
                return 'Apertura';
            case 'C':
                return 'Cierre';
            case 'E':
                if (modulo == 'Salida')
                    return `Salida`;
                return `Egreso ${modulo ? `(${modulo})` : ''}`;
            case 'I':
                if (modulo == 'Entrada')
                    return `Entrada`;
                return `Ingreso ${modulo ? `(${modulo})` : ''}`;

            default:
                return '';
        }
    }

    const cuantoFalta = () => {
        try {
            console.log(cierreDeCaja[0].Gastos);
            console.log(cierreDeCaja[0].Caja.FechaCierreCC);
            if (!cierreDeCaja[0].Caja.FechaCierreCC) {
                return '';
            }
            let gastos = cierreDeCaja[0].Gastos;
            let resultado = cierreDeCaja[0].Caja.Apertura;
            let montoCierre = cierreDeCaja[0].Caja.Cierre;
            for (const key in gastos) {
                if (Object.hasOwnProperty.call(gastos, key)) {
                    const gasto = gastos[key];
                    if (gasto.TipoG == 'E') {
                        resultado -= gasto.MontoG;
                    } else if (gasto.TipoG == 'I') {
                        resultado += gasto.MontoG;
                    }
                }
            }
            if (resultado < montoCierre) {
                return `(No cuadra, sobran $${montoCierre - resultado})`;
            }
            if (resultado > montoCierre) {
                return `(No cuadra, faltan $${resultado - montoCierre})`;
            }

        } catch (error) {

        }
        return '';
    }

    return (
        <>
            <Titulo titulo="Ver datos cierre de caja" tituloBajo="Cierres y aperturas de caja &gt; Ver datos cierre de caja"></Titulo>


            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        {
                            cierreDeCaja[0] ?
                                <>
                                    <div className="row" >
                                        <div className="widget-header bordered no-actions d-flex align-items-center justify-content-between">
                                            <h4>{cierreDeCaja[0].Caja.NombreSucursal}</h4>
                                            <h4 style={{ color: "#2c304d" }}>#{id}</h4>
                                        </div>
                                    </div>
                                    <div className="widget-body" >

                                        <div className="form-row">
                                            <div className="col-6">
                                                <label>Monto de apertura</label>
                                                <label style={{ height: "30px" }} className="form-control">{`$${cierreDeCaja[0].Caja.Apertura}`}</label>
                                            </div>
                                            <div className="col-6">
                                                <label>Monto de cierre <span style={{color: '#ee2626'}}>{cuantoFalta()}</span></label>
                                                <label style={{ height: "30px" }} className="form-control">{`$${cierreDeCaja[0].Caja.Cierre}`}</label>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-6">
                                                <label>Ingresos totales</label>
                                                <label style={{ height: "30px" }} className="form-control" onClick={f => a()}>{`$${cierreDeCaja[0].Caja.Ingresos}`}</label>
                                            </div>
                                            <div className="col-6">
                                                <label>Egresos totales</label>
                                                <label style={{ height: "30px" }} className="form-control">{`$${cierreDeCaja[0].Caja.Egresos}`}</label>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-6">
                                                <label>Fecha de apertura</label>
                                                <label style={{ height: "30px" }} className="form-control">{cierreDeCaja[0].Caja.FechaAperturaCC}</label>
                                            </div>
                                            <div className="col-6">
                                                <label>Fecha de cierre</label>
                                                <label style={{ height: "30px" }} className="form-control">{cierreDeCaja[0].Caja.FechaCierreCC}</label>
                                            </div>
                                        </div>

                                        {
                                            cierreDeCaja[0].Gastos.length > 0 ?
                                                <>
                                                    <table className="table my-3 table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Monto</th>
                                                                <th scope="col">Observaciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cierreDeCaja[0].Gastos.map((object, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {getTipo(object.TipoG, object.NombreModuloG)}
                                                                        </td>
                                                                        <td>
                                                                            {`$${object.MontoG}`}
                                                                        </td>
                                                                        <td>
                                                                            {object.ObservacionesG}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                                :
                                                <div className="text-center py-5">
                                                    <h4>Sin resultados...</h4>
                                                </div>
                                        }

                                        <div className="text-right">
                                            <Link to="/cierresDeCaja" className="btn btn-secondary">Atras</Link>
                                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                                        </div>

                                        <div id="respuesta" ></div>
                                    </div>
                                </>
                                :
                                <div className="widget-body" >{'Cargando'}</div>
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default VerCierreDeVenta;