import React from 'react';

import tecnoland from './footer-05.png'; 
import sisdesa from './footer-04.png'; 
const Footer =() => {
    return (
        <footer className="main-footer">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-center">
                    <img src={tecnoland} style={{"height": "50px"}} alt="" />
                    <div className="font-si-12 pl-3">
                        <span className="text-white" >Aviso legal </span>
                        <br />
                        <span className="text-white" >Política de privacidad</span>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center">
                    <ul className="nav">
                        <li className="nav-item">
                            <div className="font-si-12 pr-3">
                                <span className="text-white" >Todos los derechos reservados </span>
                                <br />
                                <span className="text-white" >copyright 2021 ©</span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <img src={sisdesa} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;