import React from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const Ajustes =() => {
    const URLTIPOSMOVIMIENTO= 'auth/inventarios/trnmovimientos'
    // const URLTIPOSMOVIMIENTOELIMINAR = 'auth/movimientos/tipomovimientos-eliminar/'
    const { cargando,data:tiposMovimiento, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLTIPOSMOVIMIENTO);
    const permisos  =obtenerPermisos("modulo-tipo-movimiento");



    // const eliminar = async (id)=>{
    //     const respuesta = await  swal({
    //         title: "¿estas seguro?",
    //         text: "¿Deseas eliminar esté registro?",
    //         icon: "warning",
    //         // buttons: true,
    //         buttons: ["Cancelar", "Eliminar"],
    //         dangerMode: true,
    //       })
    //       if(respuesta){
    //           try {
    //             const response =  await API.delete(URLTIPOSMOVIMIENTOELIMINAR+id);
    //             if(response.status===200){
    //                 swal("Registro elimnado correctamente", {
    //                     icon: "success",
    //                 });
    //                 cambiarPagina(paginas.filter(page=>page.active)[0].label);
    //             }

    //         } catch (error) {
    //             funciones.tratarerrores(error,document.getElementById("respuesta"));
    //         }

    //       }

    // }

    return (
    <>
    <Titulo titulo="Ajustes" tituloBajo=""></Titulo>


    <div  className="row">
        <div  className="col-xl-12">
            <div  className="widget has-shadow">
                <div  className="widget-header bordered no-actions d-flex align-items-center">
                    {/* <h4>Ajustes</h4> */}
                    <i className="demo-icon icono-listar" dangerouslySetInnerHTML={{__html: '&#xe80f;'}} ></i>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarAjuste" ></Busqueda>
                </div>
                <div  className="pb-5">
                    <div  className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h4>Cargando...</h4>
                        </div>
                        :
                        <>
                        {
                            tiposMovimiento.length >0 ?
                            <>
                            <table  className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Fecha registro</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    tiposMovimiento.map((object, index) => (
                                    <tr key={index}>
                                        <td>
                                            { object.catproducto ?
                                              object.catproducto.Nombre
                                              :
                                              ""
                                            }
                                        </td>
                                        <td>
                                            { object.cattipomovimiento ?
                                              object.cattipomovimiento.Nombre+"-"+object.cattipomovimiento.Tipo
                                              :
                                              ""
                                            }
                                        </td>
                                        <td>
                                            { 
                                                object.Cantidad 
                                            }
                                        </td>
                                        <td>{ object.created_at }</td>
                                        <td>
                                            {
                                            //    permisos.Actualizar ===1  ?
                                            //    <Link className="btn btn-primary" name="id" to={`/editar-tipomovimiento/${object.id}`}>Editar</Link>
                                            //     :
                                            //     ""
                                            }
                                            {
                                               permisos.Visualizar ===1  ?
                                               <Link className="ml-3" name="id" to={`/ver-ajuste/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                            //    permisos.Eliminar ===1  ?
                                            //    <Link className="btn btn-danger ml-3" to="#" onClick={()=>eliminar(object.id)}>Eliminar</Link> 
                                            //     :
                                            //     ""
                                            }
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h4>Sin resultados... revise que el usuario tenga un empleado con sucursal asiganada</h4>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>

    // <div  className="row">
    //     <div  className="col-xl-12">
    //         <div  className="widget has-shadow"></div>
    // </div>


    )
}

export default Ajustes;