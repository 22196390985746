import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Titulo from './../componentes/Titulo'
import obtenerCatalogoSinDefecto from '../librerias/obtenerCatalogoSinDefecto'
// const rand = () => Math.floor(Math.random() * 255);

// const genData = () => ({
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {
//       type: 'line',
//       label: 'Dataset 1',
//       borderColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
//       borderWidth: 2,
//       fill: false,
//       data: [rand(), rand(), rand(), rand(), rand(), rand()],
//     },
//     {
//       type: 'bar',
//       label: 'Dataset 2',
//       backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
//       data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
//       borderColor: 'white',
//       borderWidth: 2,
//     },
//     {
//       type: 'bar',
//       label: 'Dataset 3',
//       backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
//       data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
//     },
//   ],
// });

// const options = {
//   scales: {
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//         },
//       },
//     ],
//   },
// };

const Dashboard = () => {
  // const [data, setData] = useState(genData());
  const [kpi,setKpi] = useState({})
  const [sucursales,setSucursales] = useState({})
  const [sucursalSele,setSucusal] = useState("all")
  const [servicios,setServicios] = useState({})
  
  const traerKpis=async () => {
    const kpi= await obtenerCatalogoSinDefecto('auth/dashboard/totales/'+sucursalSele)
     console.log(kpi)
    setKpi(kpi)
  }
  
  useEffect(() => {
    traerSucursales();
    traerKpis();
    setServicios({});
    resetearKpi();
  }, [sucursalSele]);
  const traerSucursales =async () => {
    const sucursales= await obtenerCatalogoSinDefecto('auth/admin/sucursales-catalogo')
    if(sucursales || sucursales.length===0){
        sucursales.unshift({value: "all", label: 'Todos'})
        setSucursales(sucursales);
    }else{
        setSucursales({value: "", label: '--Selecciona un valor--'})
    }
    
  }
  const traerDetalles = async (estatus,color) =>{
    var contenedor = document.getElementById(estatus);
    resetearKpi();
    contenedor.style.borderWidth="3px";
    contenedor.style.borderStyle="solid";
    contenedor.style.borderColor=color;
    contenedor.style.borderBottomWidth="25px"
    contenedor.style.borderBottomColor=color
    contenedor.style.transform="scale(1.2)"
    const servicios= await obtenerCatalogoSinDefecto('auth/dashboard/totales-detalle/'+estatus+'/'+sucursalSele)
    setServicios(servicios)

  }
  const resetearKpi = ()=>{
    var kpis =document.querySelectorAll('.kpi-info-click')
    var colorIndividual =""
    kpis.forEach(element => {
       colorIndividual = element.style.borderBottomColor;
       element.style.borderWidth="";
       element.style.borderStyle="";
       element.style.borderColor="";
       element.style.borderBottomWidth="20px"
       element.style.borderBottomStyle="solid"
       element.style.borderBottomColor=colorIndividual
       element.style.transform=""
      
    });
  }
  return (
    <>
    {/* <div class="widget widget-12 has-shadow kpi" style="border-bottom: 20px solid rgb(254, 0, 0) !important;border: 3px solid rgb(246 24 24);"></div> */}
    {/* <Titulo titulo="Categoria productos" tituloBajo="Categoria productos &gt; Agregar categoria"></Titulo> */}
    <Titulo titulo="Dashboard de servicios" tituloBajo=""></Titulo>
    <div className="form-row" >
      <div className="form-group col-3">
        <label htmlFor="catsucursal_id">Sucursual*</label>
        <select className="form-control" name="catsucursal_id" id="catsucursal_id" onChange={(e)=>{setSucusal(e.target.value)}} >
            {
              sucursales.length >0 ?
              sucursales.map((object, index) => (
                  <option value={object.value}  key={index}>{object.label}</option>
              ))
              :
              ""
            }
        </select>
      </div>
    </div>
    <div className="row flex-row " id="contenedor-kpi" >
        {
          kpi.length  >0 ?
          kpi.map((object, index) => (
            <div className="col-xl-2 col-md-4 col-sm-6 " key={index} >
              <div className="widget widget-12 has-shadow kpi kpi-info-click" id={object.Estatus} onClick={()=>{traerDetalles(object.Estatus,object.Color)}}  style={{borderBottomWidth: "20px",borderBottomStyle:"solid",borderBottomColor:object.Color, borderRadius:"10px", textAlign:"center"}}>                      
                  <div className="widget-body">                         
                      <div className="media">                             
                          <div className="media-body align-self-center pl-2" >                                 
                              <div className="title-kpi text-center">{object.Descr}</div>                             
                              <div className="title text-center "  style={{fontSize:"30px", fontWeight:"bold"}}>{ object.Cant}</div>                                 
                          </div>                         
                      </div>                   
                  </div>                
              </div>            
            </div>
          ))
          :
          ""
        }
      </div>
      <div className="row" style={{minHeight:"551px"}} >
        {
          servicios.length >0 ?
          <div className="table-responsive">
            <table className="table mb-0 table-striped">
              <thead>
                  <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Creado</th>
                      <th scope="col">Total</th>
                      <th scope="col">Sucursal</th>
                      <th scope="col">Actualización Estatus</th>
                      <th scope="col">Horas</th>
                  </tr>
              </thead>
              <tbody>
              {
                servicios.map((object, index) => (
                <tr key={index}>
                  <td>
                      { 
                          object.FOLIO
                      }
                    </td>
                  <td>
                      { 
                        object.cat_cliente ?
                        object.cat_cliente.Nombre
                        :
                          ""
                      }
                    </td>
                    <td>
                      { 
                            object.created_at 
                      }
                    </td>
                  <td>
                      {
                          "$"+object.Total
                      }
                  </td>
                  <td>
                    { 
                      object.cat_sucursal ?
                      object.cat_sucursal.Nombre
                      :
                        ""
                    }
                  </td>
                  <td>
                      {
                         object.Actualizacion
                      }
                  </td>
                  <td>
                      {
                         object.Horas
                      }
                  </td>
                </tr>
                ))
              }
              </tbody>
            </table>
          </div> 
          :
          ""
        }
      </div>

      {/* <div className='header'>
        <h1 className='title'>Crazy Chart</h1>
        <div className='links'>
          <a
            className='btn btn-gh'
            href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/Crazy.js'
          >
            Github Source
          </a>
        </div>
      </div> */}
      {/* <Bar data={data} options={options} /> */}
    </>
  );
};

export default Dashboard;
