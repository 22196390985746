import React from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const Proveedores =() => {
    const URLPROVEEDORES= 'auth/inventarios/proveedores'
    const URLPROVEEDORESELIMINAR = 'auth/inventarios/proveedores-eliminar/'
    const { cargando,data:proveedores, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLPROVEEDORES);
    const permisos  =obtenerPermisos("modulo-proveedores");



    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLPROVEEDORESELIMINAR+id);
                if(response.status===200){
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    return (
    <>
    <Titulo titulo="Proveedores" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    {/* <h4>Proveedores</h4> */}
                    <i className="demo-icon icono-listar" dangerouslySetInnerHTML={{__html: '&#xe80b;'}} ></i>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarProveedor" ></Busqueda>
                </div>
                <div className="pb-5">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h4>Cargando...</h4>
                        </div>
                        :
                        <>
                        {
                            proveedores.length >0 ?
                            <>
                            <table className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Teléfono</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    proveedores.map((object, index) => (
                                    <tr key={index}>
                                        <td>{ object.Nombre }</td>
                                        <td>{ object.Telefono }</td>
                                        <td>{ object.Email }</td>
                                        <td>{ object.created_at }</td>
                                        <td>
                                            {
                                               permisos.Actualizar ===1  ?
                                               <Link className="" name="id" to={`/editar-proveedor/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                :
                                                ""
                                            }
                                            {
                                            permisos.Visualizar ===1  ?
                                            <Link className="ml-3" name="id" to={`/ver-proveedor/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                             :
                                             ""
                                            }
                                            {
                                               permisos.Eliminar ===1  ?
                                               <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                            }
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h4>Sin resultados...</h4>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>


    )
}

export default Proveedores;