

import API from './api'


export default async (catalogo)  =>{
  try {
    const response =   await API.get(catalogo)
    const data = await response.data
    return data;
  } catch (error) {
    console.log(error);
    return  0;
  }

        
}