import React, {useState ,useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import Select from 'react-select';


const EditarProducto =() => {
    const [cargandop,setCargadop] = useState(true)
    const [catproductos,setCatproductos] = useState({})
    const [cargandocatp,setCargadocatp] = useState(true)
    const [categoria,setCategoria] = useState({})
    const [productofact,setProductofact] = useState({})
    const [cargandoprodf,setCargadoprodf] = useState(true)
    const [clave,setClave] = useState(true)
    const [unidadescvlfact,setUnidadescvlfact] = useState({})
    const [cargandounidades,setCargandounidades] = useState(true)
    const [unidadcvl,setUnidadcvl] = useState(true)
    const [marcas,setMarcas] = useState({})
    const URLPRODUCTOLEER = 'auth/inventarios/productos-mostrar/'
    let { id } = useParams();
    const producto  =obtenerData(URLPRODUCTOLEER,id,1)
    const { register, handleSubmit, errors,setValue, control  } = useForm()



    const traerMarcas =async () => {
        const marcas= await obtenerCatalogo('auth/inventarios/marcas-catalogo')
        if(marcas || marcas.length===0){
            setMarcas(marcas);
        }else{
            setMarcas({value: "", label: '--Selecciona un valor--'})
        }
        
    }

    const traerUnidades =async (producto) => {
        const unidadescvlfact= await obtenerCatalogo('auth/inventarios/unidades-catalogo')
        setUnidadescvlfact(unidadescvlfact);
        setCargandounidades(false)
        var valor=producto.catunidad_id;
        var cveUnidad=unidadescvlfact.filter(cve => cve.value===valor)[0];
        if(cveUnidad){
            setUnidadcvl(cveUnidad)
            setValue("catunidad_id", cveUnidad);
        }else{
            setValue("catunidad_id",{value: null, label: '--Selecciona un valor--'})
            setUnidadcvl({value: null, label: '--Selecciona un valor--'})
        }
    }


    
    const traerCatProductos =async (producto) => {
        const catproductos= await obtenerCatalogo('auth/inventarios/categorias-catalogo')
        setCatproductos(catproductos);
        setCargadocatp(false)
        var valor=producto.catcategoria_id;
        var categoria=catproductos.filter(catPro => catPro.value===valor)[0];
        if(categoria){
            setCategoria(categoria)
            setValue("catcategoria_id", categoria);
        }else{
            setValue("catcategoria_id",{value: null, label: '--Selecciona un valor--'})
            setCategoria({value: null, label: '--Selecciona un valor--'})
        }
    }

    const traerProdFactura =async (producto) => {
        const productofact= await obtenerCatalogo('auth/inventarios/productosfacturas-catalogo')
        setProductofact(productofact);
        setValue("catproductofactura_id",{value: null, label: '--Selecciona un valor--'})
        setCargadoprodf(false)
        var valor=producto.catproductofactura_id;
        var prodFact=productofact.filter(clave => clave.value===valor)[0];
        if(prodFact){
            setClave(prodFact)
            setValue("catproductofactura_id", prodFact);
        }else{
            setValue("catproductofactura_id",{value: null, label: '--Selecciona un valor--'})
            setClave({value: null, label: '--Selecciona un valor--'})
        }
    }
    
    useEffect(
        ()=>{
        const asignar =(producto) =>{
            if(producto.id){
                setValue('Nombre', producto.Nombre)
                setValue('Codigo', producto.Codigo)
                setValue('Descripcion', producto.Descripcion)
                setValue('Costo', producto.Costo)
                setValue('Precio', producto.Precio)
                setValue('Gama', producto.Gama)
                setValue('Invminimo', producto.Invminimo)
                setValue('Tipo', producto.Tipo)
                traerMarcas();
                traerCatProductos(producto);
                traerProdFactura(producto);
                traerUnidades(producto)
            }
            // setValue('Telefono', proveedores.Telefono)
            // setValue('Email', proveedores.Email)
            // setValue('Direccion', proveedores.Direccion)
            }
        asignar(producto)
        }
    ,[setValue,producto]);
    
    const onSubmit =async (data,e) => { 
        try 
        {
            if( data.catcategoria_id.value){
                data.catcategoria_id = data.catcategoria_id.value;
                data.catproductofactura_id = data.catproductofactura_id.value;
                data.catunidad_id = data.catunidad_id.value;
                document.getElementById("enviar").disabled = true;
                const response =  await API.put('auth/inventarios/productos-actualizar/'+producto.id, data);
                if(response.status===200){
                swal({
                        title: "Actualizado",
                        text: "El producto se ha actualizado correctamente",
                        icon: "success",
                        button: "Aceptar"
                }); 
                document.getElementById("enviar").disabled = false;
                history.push('/productos')
                }else{
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response,document.getElementById("respuesta"));
                }
            }else{
                document.getElementById("respuesta").innerHTML ="Favor de seleccionar una categoria.";
            }
        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
        }
    }

    const handleChangeCatprod = e => {
        setValue("catcategoria_id", e);
        setCategoria(e)
    }
    const handleChangeProdF = e => {
        setValue("catproductofactura_id", e);
        setClave(e)
    }
    const handleChangeUnidadCvl = e => {
        setValue("catunidad_id", e);
        setUnidadcvl(e);
    }
    return (
    <>
    <Titulo titulo="Editar producto" tituloBajo="Productos &gt; Editar producto"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Producto</h4>
                    <Busqueda buscarData={buscarData} icono={icono} ></Busqueda>
                </div> */}
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Codigo">Código*</label>
                            <input type="text" className="form-control" id="Codigo" name="Codigo" ref={register({ required: true })}  />
                            {errors.Alias &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catcategoria_id">Categoria producto*</label>
                            {cargandocatp ?
                            "Cargando"
                            :
                            <Controller
                                name="catcategoria_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeCatprod}
                                    options={catproductos}
                                    isDisabled={false}
                                    value={categoria}
                                    />
                                )}
                            />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catproductofactura_id">Clave SAT*</label>
                            {cargandoprodf ?
                            "Cargando"
                            :
                            <Controller
                                name="catproductofactura_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeProdF}
                                    options={productofact}
                                    isDisabled={false}
                                    value={clave}
                                    />
                                )}
                            />
                            } 
                        </div>
                        <div className="form-group">
                            <label htmlFor="catunidad_id">Unidad Clave*</label>
                            {cargandounidades ?
                            "Cargando"
                            :
                            <Controller
                                name="catunidad_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                    onChange={handleChangeUnidadCvl}
                                    options={unidadescvlfact}
                                    isDisabled={false}
                                    value={unidadcvl}
                                    />
                                )}
                            />
                            } 
                        </div>
                        <div className="form-group">
                            <label htmlFor="Tipo">Tipo (Producto ó servicio)*</label>
                            <select className="form-control" name="Tipo" id="Tipo" ref={register({ required: true })} >
                                <option value="P">Producto</option>
                                <option value="S">Servicio</option>
                                <option value="C">Producto sin inventario</option>
                            </select>
                            {errors.EstadoCivil &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="catmarca_id">Marca*</label>
                            <select className="form-control" name="catmarca_id" id="catmarca_id" ref={register({ required: true })} >
                            {
                                marcas.length >0 ?
                                marcas.map((object, index) => (
                                    producto.id ?
                                    producto.catmarca_id ===object.value ?
                                    <option value={object.value} selected  key={index}>{object.label}</option>
                                    :
                                    <option value={object.value}  key={index}>{object.label}</option>
                                    :
                                    <option value={object.value}  key={index}>{object.label}</option>
                                ))
                                :
                                ""
                            }
                            </select>
                            {errors.catsucursal_id &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group ">
                            <label htmlFor="Gama">Gama*</label>
                            <select className="form-control " name="Gama" id="Gama" ref={register({ required: true })} >
                                <option value="">-- Seleccione --</option>
                                <option value="B">Baja</option>
                                <option value="M">Media</option>
                                <option value="A">Alta</option>
                                <option value="NA">NA</option>
                            </select>
                            {/* <input type="text" className="form-control input-90" id="Nombre" name="Nombre" ref={register({ required: true })}  /> */}
                            {errors.Gama &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Costo">Costo*</label>
                            <input type="text" className="form-control" id="Costo" name="Costo" ref={register({required: true, pattern: /^\d*(\.\d{1})?\d{0,1}$/i})} />
                            {errors.Costo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y necesita ser un número!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Precio">Precio*</label>
                            <input type="text" className="form-control" id="Precio" name="Precio" ref={register({required: true, pattern: /^\d*(\.\d{1})?\d{0,1}$/i})}  />
                            {errors.Precio &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y necesita ser un número!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="Invminimo">Inventario mínimo*</label>
                            <input type="number" className="form-control" id="Invminimo" name="Invminimo" ref={register({required: true})}  />
                            {errors.Invminimo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y necesita ser un número!
                            </div>
                            }
                        </div>   
                        <div className="form-group">
                            <label htmlFor="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/productos" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarProducto;