import React from 'react';
import API from '../librerias/api';
import swal from 'sweetalert'

const Titulo = ({ titulo, tituloBajo, excel, rutaExcel }) => {
    // inventario/generarExcel

    const generarExcel = async (ruta) => {
        try {
            let formData = new FormData();
            if (document.getElementById('fechaI')) {
                formData.append('FechaInicial', document.getElementById('fechaI').value);
            }
            if (document.getElementById('fechaF')) {
                formData.append('FechaFinal', document.getElementById('fechaF').value);
            }
            formData.append('busqueda', document.getElementById('input').value);
            formData.append('GenerarExcel', true);
            const response = await API.post(ruta, formData);
            if (response.status === 200) {
                const data = await response.data
                const nombre = data.Nombre ?? '_reporte';
                const datos = data.Documento ?? '';

                // const archivo = new File([datos], nombre, { type: "text/plain;charset=utf-8" });
                const archivo = new File([datos], nombre, { type: "application/vnd.ms-excel; charset=UTF-8" })
                const url = window.URL.createObjectURL(archivo)
                window.open(url, nombre)

            } else {
                swal({
                    title: "Error",
                    text: response.data.Mensaje[0],
                    icon: "warning",
                    button: "Aceptar"
                });
            }
        } catch (error) {
            swal({
                title: "Error",
                text: "No se puede conectar con el servidor.",
                icon: "warning",
                button: "Aceptar"
            });
        }
    }
    return (
        <div className="row">
            <div className="page-header position-relative">
                <div className="d-flex align-items-center">
                    <h2 className="page-header-title">{titulo}</h2>
                </div>
                {
                    excel ?
                        <div class="position-absolute btn-fontawesome" onClick={e => generarExcel(rutaExcel)} style={{ right: "40px", top: "10px" }}>
                            <i class="fas fa-file-excel"></i>
                        </div>
                        :
                        ""
                }
                <span style={{ fontSize: "15px", color: "#79bbd6" }} > {tituloBajo}</span>
            </div>
        </div>
    )
}
export default Titulo;